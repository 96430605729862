.modelo-slide-container {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  overflow: visible;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.modelo-slide-interno {
  width: 100%;
  height: 640px;
  display: flex;
  z-index: 3;
  overflow: hidden;
  max-width: 1200px;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  align-items: center;
  border-radius: 30px;
  flex-direction: row;
  background-color: var(--dl-color-principal-branco);
}
.modelo-slide-image-mobile {
  width: 500px;
  height: 100%;
  object-fit: cover;
}
.modelo-slide-image-desktop {
  width: 500px;
  height: 100%;
  object-fit: cover;
}
.modelo-slide-frame1000003600 {
  gap: 32px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.modelo-slide-image {
  width: 40px;
  object-fit: cover;
}
.modelo-slide-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 100%;
  height: auto;
}
.modelo-slide-text11 {
  color: rgb(23, 23, 23);
  height: auto;
  align-self: stretch;
  text-align: left;
}
.modelo-slide-indicaes {
  gap: 19px;
  display: none;
  position: relative;
  margin-top: auto;
  align-items: center;
}
.modelo-slide-djctql1 {
  width: 52px;
  height: 55px;
  border-radius: 6px;
}
.modelo-slide-vector1 {
  width: 52px;
  height: 55px;
}
.modelo-slide-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.modelo-slide-vector3 {
  width: 52px;
  height: 55px;
}
.modelo-slide-text12 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.modelo-slide-text14 {
  font-weight: 700;
}
.modelo-slideroot-class-name {
  height: 640px;
}
.modelo-slideroot-class-name1 {
  height: 640px;
}
.modelo-slideroot-class-name2 {
  height: 640px;
}
.modelo-slideroot-class-name3 {
  height: 640px;
}
.modelo-slideroot-class-name4 {
  height: 640px;
}
.modelo-slideroot-class-name5 {
  height: 640px;
}




















.modelo-slideroot-class-name26 {
  height: 100%;
}




.modelo-slideroot-class-name31 {
  height: 100%;
}
.modelo-slideroot-class-name32 {
  height: 100%;
}
.modelo-slideroot-class-name33 {
  height: 100%;
}
.modelo-slideroot-class-name34 {
  height: 100%;
}

.modelo-slideroot-class-name36 {
  height: 100%;
}
.modelo-slideroot-class-name37 {
  height: 100%;
}
.modelo-slideroot-class-name38 {
  height: 100%;
}
.modelo-slideroot-class-name39 {
  height: 100%;
}
.modelo-slideroot-class-name40 {
  height: 100%;
}
.modelo-slideroot-class-name41 {
  height: 100%;
}
.modelo-slideroot-class-name42 {
  height: 100%;
}
.modelo-slideroot-class-name43 {
  height: 100%;
}
.modelo-slideroot-class-name44 {
  height: 100%;
}
.modelo-slideroot-class-name45 {
  height: 100%;
}
.modelo-slideroot-class-name46 {
  height: 100%;
}












































@media(max-width: 1200px) {
  .modelo-slide-container {
    padding: var(--dl-space-space-twounits);
  }
  .modelo-slide-interno {
    height: 100%;
  }
  .modelo-slide-image-mobile {
    height: 100%;
  }
  .modelo-slide-image-desktop {
    height: 100%;
  }
}
@media(max-width: 991px) {
  .modelo-slide-image-desktop {
    width: 40%;
  }
}
@media(max-width: 767px) {
  .modelo-slide-interno {
    flex-direction: column;
  }
  .modelo-slide-image-mobile {
    width: 100%;
    height: 400px;
  }
  .modelo-slide-image-desktop {
    width: 30%;
  }
  .modelo-slide-frame1000003600 {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .modelo-slide-container {
    height: 70vh;
    padding: var(--dl-space-space-unit);
  }
  .modelo-slide-interno {
    height: 100%;
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
  }
  .modelo-slide-image-mobile {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
  .modelo-slide-image-desktop {
    flex: 0 0 auto;
    width: 100%;
    height: 30%;
  }
  .modelo-slide-frame1000003600 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    height: auto;
    padding: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .modelo-slide-image {
    width: 32px;
  }
  .modelo-slideroot-class-name6 {
    height: auto;
  }
  .modelo-slideroot-class-name7 {
    height: auto;
  }
  .modelo-slideroot-class-name8 {
    height: 100%;
  }
  .modelo-slideroot-class-name12 {
    height: 100%;
  }
  .modelo-slideroot-class-name13 {
    height: 100%;
  }
  .modelo-slideroot-class-name14 {
    height: 100%;
  }
  .modelo-slideroot-class-name15 {
    height: 100%;
  }
  .modelo-slideroot-class-name16 {
    height: 100%;
  }
  .modelo-slideroot-class-name17 {
    height: 100%;
  }
  .modelo-slideroot-class-name18 {
    height: 100%;
  }
  .modelo-slideroot-class-name20 {
    height: auto;
  }
  .modelo-slideroot-class-name21 {
    height: auto;
  }
  .modelo-slideroot-class-name22 {
    height: auto;
  }
  .modelo-slideroot-class-name23 {
    height: auto;
  }
  .modelo-slideroot-class-name24 {
    height: auto;
  }
  .modelo-slideroot-class-name25 {
    height: auto;
  }
  .modelo-slideroot-class-name26 {
    height: auto;
  }
  .modelo-slideroot-class-name27 {
    height: auto;
  }
  .modelo-slideroot-class-name28 {
    height: auto;
  }
  .modelo-slideroot-class-name29 {
    height: auto;
  }
  .modelo-slideroot-class-name30 {
    height: auto;
  }
  .modelo-slideroot-class-name31 {
    height: auto;
  }
  .modelo-slideroot-class-name32 {
    height: auto;
  }
  .modelo-slideroot-class-name33 {
    height: auto;
  }
  .modelo-slideroot-class-name34 {
    height: auto;
  }
  .modelo-slideroot-class-name35 {
    height: auto;
  }
  .modelo-slideroot-class-name36 {
    height: auto;
  }
  .modelo-slideroot-class-name37 {
    height: auto;
  }
  .modelo-slideroot-class-name38 {
    height: auto;
  }
  .modelo-slideroot-class-name39 {
    height: auto;
  }
  .modelo-slideroot-class-name40 {
    height: auto;
  }
  .modelo-slideroot-class-name41 {
    height: auto;
  }
  .modelo-slideroot-class-name42 {
    height: auto;
  }
  .modelo-slideroot-class-name43 {
    height: auto;
  }
  .modelo-slideroot-class-name44 {
    height: auto;
  }
  .modelo-slideroot-class-name45 {
    height: auto;
  }
  .modelo-slideroot-class-name46 {
    height: auto;
  }
  .modelo-slideroot-class-name47 {
    height: auto;
  }
  .modelo-slideroot-class-name48 {
    height: auto;
  }
  .modelo-slideroot-class-name49 {
    height: auto;
  }
  .modelo-slideroot-class-name50 {
    height: auto;
  }
  .modelo-slideroot-class-name51 {
    height: auto;
  }
  .modelo-slideroot-class-name52 {
    height: auto;
  }
  .modelo-slideroot-class-name53 {
    height: auto;
  }
  .modelo-slideroot-class-name54 {
    height: auto;
  }
  .modelo-slideroot-class-name55 {
    height: auto;
  }
  .modelo-slideroot-class-name56 {
    height: auto;
  }
  .modelo-slideroot-class-name57 {
    height: auto;
  }
  .modelo-slideroot-class-name58 {
    height: auto;
  }
  .modelo-slideroot-class-name59 {
    height: auto;
  }
  .modelo-slideroot-class-name60 {
    height: auto;
  }
  .modelo-slideroot-class-name61 {
    height: auto;
  }
  .modelo-slideroot-class-name62 {
    height: auto;
  }
  .modelo-slideroot-class-name63 {
    height: auto;
  }
  .modelo-slideroot-class-name64 {
    height: auto;
  }
  .modelo-slideroot-class-name65 {
    height: auto;
  }
  .modelo-slideroot-class-name66 {
    height: auto;
  }
  .modelo-slideroot-class-name67 {
    height: auto;
  }
  .modelo-slideroot-class-name68 {
    height: auto;
  }
  .modelo-slideroot-class-name69 {
    height: auto;
  }
  .modelo-slideroot-class-name70 {
    height: auto;
  }
  .modelo-slideroot-class-name71 {
    height: auto;
  }
  .modelo-slideroot-class-name72 {
    height: auto;
  }
  .modelo-slideroot-class-name73 {
    height: auto;
  }
  .modelo-slideroot-class-name74 {
    height: 100%;
  }
  .modelo-slideroot-class-name75 {
    height: 100%;
  }
  .modelo-slideroot-class-name76 {
    height: 100%;
  }
  .modelo-slideroot-class-name77 {
    height: 100%;
  }
  .modelo-slideroot-class-name78 {
    height: 100%;
  }
  .modelo-slideroot-class-name79 {
    height: 100%;
  }
  .modelo-slideroot-class-name80 {
    height: 100%;
  }
  .modelo-slideroot-class-name81 {
    height: 100%;
  }
  .modelo-slideroot-class-name82 {
    height: 100%;
  }
  .modelo-slideroot-class-name83 {
    height: 100%;
  }
  .modelo-slideroot-class-name84 {
    height: auto;
  }
  .modelo-slideroot-class-name85 {
    height: auto;
  }
  .modelo-slideroot-class-name86 {
    height: auto;
  }
  .modelo-slideroot-class-name87 {
    height: auto;
  }
  .modelo-slideroot-class-name88 {
    height: auto;
  }
  .modelo-slideroot-class-name89 {
    height: auto;
  }
  .modelo-slideroot-class-name90 {
    height: auto;
  }
}
