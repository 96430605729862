.pag2-sanfona5-container1 {
  flex-direction: column;
}
.pag2-sanfona5-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag2-sanfona5-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag2-sanfona5-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag2-sanfona5-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag2-sanfona5-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag2-sanfona5-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag2-sanfona5-container2:active {
  cursor: grabbing;
}
.pag2-sanfona5-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag2-sanfona5-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag2-sanfona5-image {
  flex: 1;
  width: 50%;
  align-self: stretch;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag2-sanfona5-container5 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
}
.pag2-sanfona5-container5:active {
  cursor: grabbing;
}
.pag2-sanfona5-slider {
  width: 100%;
  cursor: grab;
  height: 100%;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag2-sanfona5-slider-slide1 {
  height: 100%;
  display: flex;
}
.pag2-sanfona5-text12 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona5-text13 {
  display: inline-block;
}
.pag2-sanfona5-text20 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag2-sanfona5-slider-slide2 {
  display: flex ;
}
.pag2-sanfona5-text22 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona5-text23 {
  display: inline-block;
}
.pag2-sanfona5-text25 {
  font-weight: 700;
}
.pag2-sanfona5-text32 {
  font-weight: 700;
}
.pag2-sanfona5-slider-slide3 {
  display: flex ;
}
.pag2-sanfona5-text33 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona5-text34 {
  display: inline-block;
}
.pag2-sanfona5-text36 {
  font-weight: 700;
}
.pag2-sanfona5-text41 {
  font-weight: 700;
}
.pag2-sanfona5-text43 {
  font-weight: 700;
}
.pag2-sanfona5-slider-slide4 {
  display: flex ;
}
.pag2-sanfona5-text45 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona5-text46 {
  display: inline-block;
}
.pag2-sanfona5-text48 {
  font-weight: 700;
}
.pag2-sanfona5-text50 {
  font-weight: 700;
}
.pag2-sanfona5-text51 {
  font-weight: 700;
}
.pag2-sanfona5-text54 {
  font-weight: 700;
}
.pag2-sanfona5-slider-slide5 {
  display: flex ;
}
.pag2-sanfona5-text56 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona5-text57 {
  display: inline-block;
}
.pag2-sanfona5-text59 {
  font-weight: 700;
}
.pag2-sanfona5-text61 {
  font-weight: 700;
}
.pag2-sanfona5-text63 {
  font-weight: 700;
}
.pag2-sanfona5-text68 {
  font-weight: 700;
}
.pag2-sanfona5-slider-slide6 {
  display: flex ;
}
.pag2-sanfona5-text70 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona5-text71 {
  display: inline-block;
}
.pag2-sanfona5-text73 {
  font-weight: 700;
}
.pag2-sanfona5-text78 {
  font-weight: 700;
}
.pag2-sanfona5-text80 {
  font-weight: 700;
}
.pag2-sanfona5-slider-pagination {
  display: block;
}
.pag2-sanfona5-slider-button-prev {
  display: none;
}
.pag2-sanfona5-slider-button-next {
  display: none;
}
.pag2-sanfona5-text82 {
  width: 100%;
  display: inline-block;
}
.pag2-sanfona5-text84 {
  font-weight: 700;
}
.pag2-sanfona5-text89 {
  font-weight: 700;
}

@media(max-width: 1200px) {
  .pag2-sanfona5-slider {
    height: 820px;
  }
}
@media(max-width: 767px) {
  .pag2-sanfona5-container3 {
    flex-direction: column;
  }
  .pag2-sanfona5-container4 {
    width: 100%;
  }
  .pag2-sanfona5-image {
    width: 100%;
  }
  .pag2-sanfona5-slider {
    height: 1155px;
  }
}
@media(max-width: 479px) {
  .pag2-sanfona5-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag2-sanfona5-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag2-sanfona5-icone-ativo {
    width: 20px;
  }
  .pag2-sanfona5-icone-inativo {
    width: 20px;
  }
  .pag2-sanfona5-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .pag2-sanfona5-container3 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .pag2-sanfona5-container4 {
    width: 100%;
  }
  .pag2-sanfona5-image {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag2-sanfona5-container5 {
    height: auto;
  }
  .pag2-sanfona5-slider {
    height: 789px;
  }
}
