.pag6-sanfona2-container1 {
  flex-direction: column;
}
.pag6-sanfona2-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag6-sanfona2-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag6-sanfona2-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag6-sanfona2-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag6-sanfona2-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag6-sanfona2-container2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag6-sanfona2-container2:active {
  cursor: grabbing;
}
.pag6-sanfona2-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag6-sanfona2-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag6-sanfona2-text13 {
  font-weight: 700;
}
.pag6-sanfona2-container5 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: flex-start;
  align-items: flex-end;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: flex-start;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag6-sanfona2-container6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.pag6-sanfona2-text15 {
  width: 391px;
  font-family: "BlauerNue-Light";
}
.pag6-sanfona2-container7 {
  flex: 1;
  width: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
}
.pag6-sanfona2-image1 {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  object-fit: cover;
}
.pag6-sanfona2-image2 {
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  object-fit: cover;
}

@media(max-width: 1200px) {
  .pag6-sanfona2-container5 {
    gap: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .pag6-sanfona2-container3 {
    flex-direction: column;
  }
  .pag6-sanfona2-container4 {
    width: 100%;
  }
  .pag6-sanfona2-container5 {
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 0px;
  }
  .pag6-sanfona2-container6 {
    width: 100%;
    padding-top: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }
  .pag6-sanfona2-image1 {
    position: static;
  }
}
@media(max-width: 767px) {
  .pag6-sanfona2-container3 {
    flex-direction: column;
  }
  .pag6-sanfona2-container4 {
    width: 100%;
  }
  .pag6-sanfona2-container5 {
    padding-right: 0px;
    flex-direction: column;
  }
  .pag6-sanfona2-container6 {
    width: 100%;
  }
  .pag6-sanfona2-text15 {
    width: auto;
  }
}
@media(max-width: 479px) {
  .pag6-sanfona2-container1 {
    width: 447px;
  }
  .pag6-sanfona2-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag6-sanfona2-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag6-sanfona2-icone-ativo {
    width: 20px;
  }
  .pag6-sanfona2-icone-inativo {
    width: 20px;
  }
  .pag6-sanfona2-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag6-sanfona2-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag6-sanfona2-container4 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag6-sanfona2-container5 {
    gap: var(--dl-space-space-twounits);
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0pz;
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: 0pz;
    flex-direction: column;
    padding-bottom: 0px;
    justify-content: flex-start;
  }
  .pag6-sanfona2-container6 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .pag6-sanfona2-text15 {
    width: 100%;
  }
  .pag6-sanfona2-image2 {
    top: -18px;
    left: 40px;
    right: auto;
    width: 80%;
    height: auto;
  }
  .pag6-sanfona2root-class-name {
    width: 100%;
  }
}
