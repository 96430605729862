.pagina2-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-principal-branco);
}
.pagina2-hero {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.pagina2-image1 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  position: absolute;
  box-shadow: 5px 5px 60px 20px #29b9f2;
  object-fit: cover;
}
.pagina2-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1200px;
  min-width: auto;
  align-items: center;
  flex-direction: column;
}
.pagina2-container3 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  align-items: flex-start;
}
.pagina2-text10 {
  fill: var(--dl-color-principal-verde);
  color: var(--dl-color-principal-verde);
  font-size: 48px;
  font-style: italic;
  font-weight: 400;
}
.pagina2-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 735px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pagina2-text11 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 100%;
}
.pagina2-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.pagina2-boto {
  gap: 10px;
  display: flex;
  padding: 20px;
  z-index: 2;
  align-items: center;
  margin-right: var(--dl-space-space-fourunits);
  border-radius: 30px;
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina2-text15 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600px;
  font-stretch: normal;
}
.pagina2-indicaes {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
}
.pagina2-vector1 {
  width: 52px;
  height: 55px;
}
.pagina2-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.pagina2-vector3 {
  width: 52px;
  height: 55px;
}
.pagina2-image2 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pagina2-container6 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.pagina2-vector4 {
  width: 36px;
  height: 72px;
}
.pagina2-text17 {
  font-weight: 700;
}
.pagina2-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.pagina2-text21 {
  width: 100%;
  display: inline-block;
}
.pagina2-text22 {
  width: 100%;
  display: inline-block;
}
.pagina2-text24 {
  font-family: "BlauerNue-Bold";
}
.pagina2-text25 {
  width: 100%;
  display: inline-block;
}
.pagina2-text27 {
  font-weight: 700;
}
.pagina2-text29 {
  font-weight: 700;
}
.pagina2-text34 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina2-text35 {
  width: 100%;
  display: inline-block;
}
.pagina2-text37 {
  font-weight: 700;
}
.pagina2-text39 {
  width: 100%;
  display: inline-block;
}
.pagina2-text41 {
  font-weight: 700;
}
.pagina2-text43 {
  font-weight: 700;
}
.pagina2-text47 {
  font-weight: 700;
}
.pagina2-text52 {
  font-weight: 700;
}
.pagina2-text53 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Thin";
}
.pagina2-text55 {
  font-family: "BlauerNue-Bold";
}
.pagina2-text56 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Thin";
}
.pagina2-text58 {
  font-family: "BlauerNue-Bold";
}
.pagina2-text59 {
  width: 100%;
  display: inline-block;
}
.pagina2-text61 {
  font-weight: 700;
}
.pagina2-text63 {
  font-weight: 700;
}
.pagina2-text68 {
  width: 100%;
  display: inline-block;
}
.pagina2-text70 {
  font-weight: 700;
}
.pagina2-text75 {
  font-weight: 700;
}
.pagina2-text77 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 90%;
  height: auto;
  display: inline-block;
}
.pagina2-text79 {
  font-weight: 700;
}
.pagina2-text81 {
  width: 100%;
  display: inline-block;
}
.pagina2-text83 {
  font-weight: 700;
}
.pagina2-sugestao-conteudo {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina2-interno {
  gap: -97px;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-twounits);
}
.pagina2-texto-ei {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pagina2-text85 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  text-align: left;
}
.pagina2-text86 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 440px;
  align-self: stretch;
  text-align: left;
  letter-spacing: 0em;
}
.pagina2-button {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  margin-top: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-secundario-rosa);
}
.pagina2-container8 {
  flex: 0 0 auto;
  right: -117px;
  width: 703px;
  bottom: 0px;
  height: 376px;
  display: flex;
  position: absolute;
  align-self: flex-end;
  align-items: flex-end;
  pointer-events: none;
  justify-content: center;
}
.pagina2-image3 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.pagina2-container9 {
  flex: 0 0 auto;
  right: 0px;
  width: 703px;
  bottom: 0px;
  height: 376px;
  display: none;
  position: absolute;
  align-self: flex-end;
  align-items: flex-end;
  pointer-events: none;
  justify-content: flex-end;
}
.pagina2-image4 {
  width: auto;
  height: 100%;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .pagina2-container3 {
    width: auto;
  }
  .pagina2-text11 {
    color: var(--dl-color-principal-branco);
  }
  .pagina2-sugestao-conteudo {
    height: auto;
  }
  .pagina2-interno {
    height: auto;
  }
  .pagina2-texto-ei {
    width: 419px;
    padding-top: var(--dl-space-space-twounits);
    margin-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .pagina2-container8 {
    width: 540px;
    bottom: 0px;
  }
  .pagina2-container9 {
    width: 540px;
  }
}
@media(max-width: 991px) {
  .pagina2-image1 {
    height: 506px;
  }
  .pagina2-container3 {
    width: 100%;
  }
  .pagina2-container4 {
    flex: 1;
    width: 100%;
  }
  .pagina2-sugestao-conteudo {
    height: auto;
  }
  .pagina2-interno {
    height: auto;
  }
  .pagina2-texto-ei {
    width: 420px;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pagina2-container8 {
    justify-content: center;
  }
  .pagina2-container9 {
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .pagina2-image1 {
    height: 695px;
  }
  .pagina2-container3 {
    width: 100%;
    flex-direction: column;
  }
  .pagina2-container4 {
    width: 100%;
  }
  .pagina2-sugestao-conteudo {
    padding: 0px;
    flex-direction: column;
  }
  .pagina2-interno {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .pagina2-container8 {
    width: 100%;
    position: static;
  }
  .pagina2-image3 {
    width: 100%;
    transform-origin: bottom;
  }
  .pagina2-container9 {
    width: 100%;
    position: static;
  }
  .pagina2-image4 {
    width: 100%;
    transform-origin: bottom;
  }
}
@media(max-width: 479px) {
  .pagina2-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .pagina2-image1 {
    height: 240px;
  }
  .pagina2-container2 {
    gap: var(--dl-space-space-unit);
  }
  .pagina2-container3 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .pagina2-text10 {
    font-size: 16px;
  }
  .pagina2-container4 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    width: 100%;
    height: auto;
  }
  .pagina2-text11 {
    color: var(--dl-color-principal-branco);
    width: auto;
  }
  .pagina2-text13 {
    font-family: BlauerNue-Regular;
  }
  .pagina2-container5 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .pagina2-boto {
    padding-top: 10px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 10px;
  }
  .pagina2-text15 {
    color: var(--dl-color-secundario-azul-forte);
  }
  .pagina2-indicaes {
    gap: var(--dl-space-space-halfunit);
  }
  .pagina2-vector1 {
    width: 24px;
    height: 24px;
  }
  .pagina2-vector2 {
    width: 24px;
    height: 24px;
  }
  .pagina2-vector3 {
    width: 24px;
    height: 24px;
  }
  .pagina2-image2 {
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pagina2-container6 {
    gap: var(--dl-space-space-unit);
  }
  .pagina2-vector4 {
    width: 10px;
    height: 19px;
  }
  .pagina2-container7 {
    padding: 0px;
  }
  .pagina2-text37 {
    font-weight: 700;
  }
  .pagina2-sugestao-conteudo {
    height: auto;
    padding: 0px;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pagina2-interno {
    gap: var(--dl-space-space-fourunits);
    height: auto;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .pagina2-texto-ei {
    gap: var(--dl-space-space-unit);
    width: 100%;
    margin-left: 0px;
    padding-top: var(--dl-space-space-twounits);
    margin-right: 0px;
    padding-bottom: 0px;
  }
  .pagina2-text85 {
    width: 100%;
  }
  .pagina2-text86 {
    color: var(--dl-color-secundario-azul-forte);
    width: 100%;
    letter-spacing: 0em;
  }
  .pagina2-button {
    margin-top: 0px;
    padding-top: 12px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 12px;
    text-decoration: none;
  }
  .pagina2-container8 {
    width: 100%;
    height: auto;
    z-index: 4;
    position: static;
    transform: scale(1.2);
    transform-origin: bottom;
  }
  .pagina2-image3 {
    width: 100%;
    transform: scale(1.2);
  }
  .pagina2-container9 {
    width: 100%;
    height: auto;
    z-index: 4;
    position: static;
    transform: scale(1.2);
    transform-origin: bottom;
  }
  .pagina2-image4 {
    width: 100%;
    transform: scale(1.1)\;
  }
}
