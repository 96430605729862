.pag4-sanfona2-container1 {
  flex-direction: column;
}
.pag4-sanfona2-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag4-sanfona2-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag4-sanfona2-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona2-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag4-sanfona2-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag4-sanfona2-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag4-sanfona2-container2:active {
  cursor: grabbing;
}
.pag4-sanfona2-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona2-container4 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: 40%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-secundario-rosa);
}
.pag4-sanfona2-image1 {
  width: 100%;
  object-fit: cover;
}
.pag4-sanfona2-image2 {
  width: 100%;
  height: 100%;
  align-self: stretch;
  object-fit: cover;
}
.pag4-sanfona2-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona2-text14 {
  font-weight: 700;
}
.pag4-sanfona2-text22 {
  font-weight: 700;
}
.pag4-sanfona2-text24 {
  font-weight: 700;
}
.pag4-sanfona2-text29 {
  font-weight: 700;
}
.pag4-sanfona2-container6 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag4-sanfona2-container7 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona2-container8 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 56%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona2-text31 {
  font-family: "BlauerNue-Light";
}
.pag4-sanfona2-text35 {
  font-weight: 700;
}
.pag4-sanfona2-text37 {
  font-weight: 700;
}
.pag4-sanfona2-text38 {
  font-weight: 700;
}
.pag4-sanfona2-text41 {
  font-weight: 700;
}
.pag4-sanfona2-text44 {
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona2-image3 {
  width: 40%;
  height: 432px;
  object-fit: cover;
}
.pag4-sanfona2-text45 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pag4-sanfona2-text47 {
  font-family: "BlauerNue-Light";
}

@media(max-width: 1200px) {
  .pag4-sanfona2-container4 {
    height: auto;
    align-self: stretch;
    flex-direction: column;
  }
  .pag4-sanfona2-image2 {
    width: auto;
    height: 100%;
    align-self: stretch;
  }
  .pag4-sanfona2-container6 {
    gap: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .pag4-sanfona2-image3 {
    top: auto;
    right: 0px;
    width: 40%;
    bottom: 0px;
    height: auto;
    align-self: stretch;
  }
}
@media(max-width: 991px) {
  .pag4-sanfona2-container3 {
    flex-direction: column-reverse;
  }
  .pag4-sanfona2-container4 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }
  .pag4-sanfona2-image1 {
    flex: 1;
    width: 40%;
    border-radius: 0px;
  }
  .pag4-sanfona2-image2 {
    flex: 1;
    width: 40%;
  }
  .pag4-sanfona2-container5 {
    width: 100%;
  }
  .pag4-sanfona2-container6 {
    align-items: center;
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .pag4-sanfona2-container7 {
    flex-direction: column;
  }
  .pag4-sanfona2-container8 {
    width: 100%;
  }
  .pag4-sanfona2-image3 {
    width: 100%;
    height: 380px;
    position: static;
  }
}
@media(max-width: 767px) {
  .pag4-sanfona2-container3 {
    flex-direction: column-reverse;
  }
  .pag4-sanfona2-container4 {
    flex-direction: column;
  }
  .pag4-sanfona2-image1 {
    width: 100%;
  }
  .pag4-sanfona2-image2 {
    width: 100%;
    height: auto;
  }
  .pag4-sanfona2-container5 {
    width: 100%;
  }
  .pag4-sanfona2-container6 {
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pag4-sanfona2-container8 {
    width: 100%;
  }
  .pag4-sanfona2-image3 {
    width: 100%;
    height: auto;
  }
}
@media(max-width: 479px) {
  .pag4-sanfona2-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag4-sanfona2-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona2-icone-ativo {
    width: 20px;
  }
  .pag4-sanfona2-icone-inativo {
    width: 20px;
  }
  .pag4-sanfona2-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona2-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .pag4-sanfona2-container4 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona2-image1 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag4-sanfona2-image2 {
    width: 100%;
    height: auto;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag4-sanfona2-container5 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag4-sanfona2-container6 {
    gap: var(--dl-space-space-twounits);
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .pag4-sanfona2-container7 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona2-container8 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag4-sanfona2-image3 {
    width: 100%;
    margin: 0px;
  }
}
