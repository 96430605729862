.pag1-sanfona1-container1 {
  flex-direction: column;
}
.pag1-sanfona1-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.pag1-sanfona1-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag1-sanfona1-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona1-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag1-sanfona1-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag1-sanfona1-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag1-sanfona1-container2:active {
  cursor: grabbing;
}
.pag1-sanfona1-slider {
  width: 100%;
  cursor: grab;
  height: auto;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag1-sanfona1-slider-slide1 {
  display: flex;
  flex-direction: column;
}
.pag1-sanfona1-text11 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona1-text12 {
  display: inline-block;
}
.pag1-sanfona1-text14 {
  font-weight: 700;
}
.pag1-sanfona1-text21 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag1-sanfona1-slider-slide2 {
  display: flex;
  flex-direction: column;
}
.pag1-sanfona1-text22 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona1-text23 {
  display: inline-block;
}
.pag1-sanfona1-text32 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag1-sanfona1-slider-slide3 {
  display: flex;
}
.pag1-sanfona1-text34 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona1-text35 {
  display: inline-block;
}
.pag1-sanfona1-text36 {
  font-weight: 700;
}
.pag1-sanfona1-text41 {
  font-weight: 700;
}
.pag1-sanfona1-slider-pagination {
  display: block;
}
.pag1-sanfona1-slider-button-prev {
  display: none;
}
.pag1-sanfona1-slider-button-next {
  display: none;
}

@media(max-width: 1200px) {
  .pag1-sanfona1-slider {
    height: 820px;
  }
}
@media(max-width: 991px) {
  .pag1-sanfona1-slider {
    height: 872px;
  }
}
@media(max-width: 767px) {
  .pag1-sanfona1-slider {
    height: 1256px;
  }
}
@media(max-width: 479px) {
  .pag1-sanfona1-container1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag1-sanfona1-topo {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .pag1-sanfona1-botoes {
    margin-left: var(--dl-space-space-unit);
  }
  .pag1-sanfona1-icone-ativo {
    width: 20px;
  }
  .pag1-sanfona1-icone-inativo {
    width: 20px;
  }
  .pag1-sanfona1-container2 {
    height: fit-content;
    padding-top: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag1-sanfona1-slider {
    height: 777px;
  }
  .pag1-sanfona1-text36 {
    font-weight: 700;
  }
  .pag1-sanfona1-text41 {
    font-weight: 700;
  }
}
