.pag6-sanfona4-container10 {
  flex-direction: column;
}
.pag6-sanfona4-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag6-sanfona4-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag6-sanfona4-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag6-sanfona4-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag6-sanfona4-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag6-sanfona4-container11 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag6-sanfona4-container11:active {
  cursor: grabbing;
}
.pag6-sanfona4-container12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag6-sanfona4-text13 {
  font-weight: 700;
}
.pag6-sanfona4-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-principal-verde);
}
.pag6-sanfona4-container14 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag6-sanfona4-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 100px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag6-sanfona4-link10 {
  text-decoration: none;
}
.pag6-sanfona4-link11 {
  text-decoration: none;
}
.pag6-sanfona4-link12 {
  text-decoration: none;
}
.pag6-sanfona4-link13 {
  text-decoration: none;
}
.pag6-sanfona4-container16 {
  width: 40%;
  display: flex;
  align-self: stretch;
  padding-top: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag6-sanfona4-image1 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.pag6-sanfona4-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-principal-verde);
}
.pag6-sanfona4-container18 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 480px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag6-sanfona4-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 100px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag6-sanfona4-link14 {
  text-decoration: none;
}
.pag6-sanfona4-link15 {
  text-decoration: none;
}
.pag6-sanfona4-image2 {
  width: 40%;
  align-self: stretch;
  object-fit: cover;
}
.pag6-sanfona4-container20 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  background-color: var(--dl-color-principal-verde);
}
.pag6-sanfona4-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.pag6-sanfona4-container22 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 421px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag6-sanfona4-container23 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 100px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag6-sanfona4-link16 {
  text-decoration: none;
}
.pag6-sanfona4-container24 {
  width: 40%;
  height: 292px;
  display: flex;
  align-self: stretch;
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag6-sanfona4-image3 {
  width: auto;
  height: 100%;
  align-self: stretch;
  object-fit: cover;
}
.pag6-sanfona4-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag6-sanfona4-container26 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag6-sanfona4-link17 {
  text-decoration: none;
}
.pag6-sanfona4-link18 {
  text-decoration: none;
}
.pag6-sanfona4-link19 {
  text-decoration: none;
}
.pag6-sanfona4-link20 {
  text-decoration: none;
}
.pag6-sanfona4-container27 {
  width: 40%;
  height: 292px;
  display: none;
  align-self: stretch;
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag6-sanfona4-image4 {
  width: auto;
  height: 100%;
  align-self: stretch;
  object-fit: cover;
}
.pag6-sanfona4-text20 {
  display: inline-block;
}
.pag6-sanfona4-text21 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text22 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text23 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text26 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text27 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text30 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text31 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text35 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text36 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text37 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text40 {
  display: inline-block;
}
.pag6-sanfona4-text41 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text42 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text45 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text46 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text47 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text49 {
  display: inline-block;
}
.pag6-sanfona4-text50 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag6-sanfona4-text51 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text52 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text56 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag6-sanfona4-text57 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text58 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text62 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag6-sanfona4-text63 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text64 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text67 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text68 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text69 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text72 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  display: inline-block;
}
.pag6-sanfona4-text73 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text74 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text75 {
  font-weight: 700;
  text-decoration: underline;
}
.pag6-sanfona4-text76 {
  font-weight: 700;
  text-decoration: underline;
}

@media(max-width: 991px) {
  .pag6-sanfona4-container12 {
    width: 100%;
  }
  .pag6-sanfona4-container13 {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .pag6-sanfona4-container14 {
    flex: 1;
    width: 100%;
  }
  .pag6-sanfona4-container16 {
    width: 100%;
    align-self: center;
    padding-top: var(--dl-space-space-twounits);
  }
  .pag6-sanfona4-image1 {
    height: 400px;
  }
  .pag6-sanfona4-container17 {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .pag6-sanfona4-container18 {
    flex: 1;
    width: 100%;
  }
  .pag6-sanfona4-image2 {
    width: 100%;
    height: auto;
  }
  .pag6-sanfona4-container20 {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .pag6-sanfona4-container21 {
    flex-direction: column-reverse;
  }
  .pag6-sanfona4-container22 {
    flex: 1;
    width: 100%;
  }
  .pag6-sanfona4-container24 {
    width: 100%;
    display: none;
    align-self: center;
    padding-top: var(--dl-space-space-twounits);
  }
  .pag6-sanfona4-image3 {
    height: 400px;
  }
  .pag6-sanfona4-container26 {
    flex: 1;
    width: 100%;
  }
  .pag6-sanfona4-container27 {
    width: 100%;
    display: flex;
    align-self: center;
  }
  .pag6-sanfona4-image4 {
    height: 100%;
  }
  .pag6-sanfona4-text21 {
    width: 100%;
  }
  .pag6-sanfona4-text26 {
    width: 100%;
  }
  .pag6-sanfona4-text30 {
    width: 100%;
  }
  .pag6-sanfona4-text35 {
    width: 100%;
  }
  .pag6-sanfona4-text41 {
    width: 100%;
  }
  .pag6-sanfona4-text45 {
    width: 100%;
  }
  .pag6-sanfona4-text51 {
    width: 100%;
  }
  .pag6-sanfona4-text57 {
    width: 100%;
  }
  .pag6-sanfona4-text63 {
    width: 100%;
  }
  .pag6-sanfona4-text67 {
    width: 100%;
  }
  .pag6-sanfona4-text72 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .pag6-sanfona4-container12 {
    width: 100%;
  }
  .pag6-sanfona4-container13 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .pag6-sanfona4-container14 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
  }
  .pag6-sanfona4-container17 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .pag6-sanfona4-container18 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
  }
  .pag6-sanfona4-container20 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .pag6-sanfona4-container22 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
  }
  .pag6-sanfona4-container24 {
    display: none;
  }
  .pag6-sanfona4-container26 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
  }
  .pag6-sanfona4-container27 {
    height: auto;
    display: flex;
  }
}
@media(max-width: 479px) {
  .pag6-sanfona4-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag6-sanfona4-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag6-sanfona4-icone-ativo {
    width: 20px;
  }
  .pag6-sanfona4-icone-inativo {
    width: 20px;
  }
  .pag6-sanfona4-container11 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag6-sanfona4-container12 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag6-sanfona4-container13 {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag6-sanfona4-container14 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag6-sanfona4-container15 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .pag6-sanfona4-image1 {
    height: 210px;
  }
  .pag6-sanfona4-container17 {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag6-sanfona4-container18 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag6-sanfona4-container19 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .pag6-sanfona4-image2 {
    height: auto;
  }
  .pag6-sanfona4-container20 {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag6-sanfona4-container22 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag6-sanfona4-container23 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .pag6-sanfona4-image3 {
    height: 210px;
  }
  .pag6-sanfona4-container26 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag6-sanfona4-container27 {
    padding-top: 0px;
  }
  .pag6-sanfona4-image4 {
    height: 210px;
  }
  .pag6-sanfona4-text21 {
    width: 100%;
  }
  .pag6-sanfona4-text26 {
    width: 100%;
  }
  .pag6-sanfona4-text30 {
    width: 100%;
  }
  .pag6-sanfona4-text35 {
    width: 100%;
  }
  .pag6-sanfona4-text41 {
    width: 100%;
  }
  .pag6-sanfona4-text45 {
    width: 100%;
  }
  .pag6-sanfona4-text51 {
    width: 100%;
  }
  .pag6-sanfona4-text57 {
    width: 100%;
  }
  .pag6-sanfona4-text63 {
    width: 100%;
  }
  .pag6-sanfona4-text67 {
    width: 100%;
  }
  .pag6-sanfona4-text72 {
    width: 100%;
  }
}
