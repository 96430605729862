.formulario-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.formulario-seo1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 140px;
  background-size: cover;
  justify-content: center;
  background-image: url("/Pagina Formulario/formulario-1500w.png");
}
.formulario-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: column;
}
.formulario-container3 {
  flex: 0 0 auto;
  width: 64%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.formulario-text1 {
  fill: var(--dl-color-principal-verde);
  color: var(--dl-color-principal-verde);
  height: auto;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-weight: 800px;
  font-stretch: normal;
  margin-bottom: var(--dl-space-space-unit);
}
.formulario-text5 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 474px;
  height: auto;
  font-style: normal;
  text-align: left;
  font-weight: 500px;
  font-stretch: normal;
  margin-bottom: var(--dl-space-space-threeunits);
}
.formulario-text7 {
  font-weight: 700;
}
.formulario-form {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius30);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.formulario-select1 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textarea {
  width: 100%;
  height: 120px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-container4 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.formulario-textinput10 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-select2 {
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput11 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput12 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput13 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput14 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput15 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput16 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput17 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput18 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-textinput19 {
  width: 100%;
  display: none;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.formulario-container5 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.formulario-container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-criatividade-unabranco);
}
.formulario-checkbox {
  width: 32px;
  height: 32px;
  outline: 2px solid white;
  outline-offset: -2px;
}
.formulario-text9 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
}
.formulario-button {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  cursor: pointer;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-principal-verde);
}
.formulario-image {
  top: 119px;
  right: 59px;
  width: 568px;
  height: 459px;
  position: absolute;
  object-fit: cover;
  pointer-events: none;
}
@media(max-width: 991px) {
  .formulario-container2 {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .formulario-container3 {
    width: 100%;
  }
  .formulario-image {
    display: none;
  }
}
@media(max-width: 479px) {
  .formulario-seo1 {
    overflow: hidden;
    padding-top: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 180px;
  }
  .formulario-container3 {
    width: 100%;
  }
  .formulario-text5 {
    width: 100%;
    z-index: 100;
  }
  .formulario-form {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .formulario-image {
    top: auto;
    left: auto;
    right: -60px;
    width: 310px;
    bottom: -140px;
    height: 225px;
    margin: auto;
    position: absolute;
  }
}
