.pag4-sanfona4-container1 {
  flex-direction: column;
}
.pag4-sanfona4-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag4-sanfona4-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag4-sanfona4-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona4-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag4-sanfona4-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag4-sanfona4-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag4-sanfona4-container2:active {
  cursor: grabbing;
}
.pag4-sanfona4-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona4-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona4-text13 {
  font-weight: 700;
}
.pag4-sanfona4-text18 {
  font-weight: 700;
}
.pag4-sanfona4-text23 {
  font-weight: 700;
}
.pag4-sanfona4-image1 {
  width: 40%;
  align-self: stretch;
  object-fit: cover;
}
.pag4-sanfona4-container5 {
  gap: 0px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona4-text25 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 784px;
  font-family: "BlauerNue-Bold";
}
.pag4-sanfona4-text26 {
  font-family: "BlauerNue-Light";
}
.pag4-sanfona4-text28 {
  font-family: "BlauerNue-Light";
}
.pag4-sanfona4-slider {
  width: 100%;
  cursor: grab;
  height: auto;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag4-sanfona4-slider-slide1 {
  display: flex;
}
.pag4-sanfona4-text29 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona4-text30 {
  display: inline-block;
}
.pag4-sanfona4-text34 {
  font-weight: 700;
}
.pag4-sanfona4-text39 {
  font-weight: 700;
}
.pag4-sanfona4-text44 {
  font-weight: 700;
}
.pag4-sanfona4-text46 {
  font-weight: 700;
}
.pag4-sanfona4-slider-slide2 {
  display: flex;
}
.pag4-sanfona4-text48 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona4-text49 {
  display: inline-block;
}
.pag4-sanfona4-text54 {
  font-weight: 700;
}
.pag4-sanfona4-slider-slide3 {
  display: flex;
}
.pag4-sanfona4-text56 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona4-text57 {
  display: inline-block;
}
.pag4-sanfona4-text59 {
  font-weight: 700;
}
.pag4-sanfona4-text64 {
  font-weight: 700;
}
.pag4-sanfona4-slider-pagination {
  display: block;
}
.pag4-sanfona4-slider-button-prev {
  display: none;
}
.pag4-sanfona4-slider-button-next {
  display: none;
}
.pag4-sanfona4-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona4-container7 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag4-sanfona4-container8 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.pag4-sanfona4-container9 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona4-text65 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
}
.pag4-sanfona4-text66 {
  font-family: "BlauerNue-Light";
}
.pag4-sanfona4-text70 {
  font-weight: 700;
}
.pag4-sanfona4-text72 {
  font-weight: 700;
}
.pag4-sanfona4-text77 {
  font-weight: 700;
}
.pag4-sanfona4-text79 {
  font-weight: 700;
}
.pag4-sanfona4-image2 {
  width: 50%;
  height: auto;
  object-fit: cover;
}
.pag4-sanfona4-text81 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pag4-sanfona4-text82 {
  font-family: "BlauerNue-Light";
}

@media(max-width: 1200px) {
  .pag4-sanfona4-slider {
    height: 679px;
  }
  .pag4-sanfona4-container7 {
    gap: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .pag4-sanfona4-image2 {
    top: auto;
    right: 0px;
    width: 50%;
    bottom: 0px;
  }
}
@media(max-width: 991px) {
  .pag4-sanfona4-container3 {
    flex-direction: row;
  }
  .pag4-sanfona4-container4 {
    width: 100%;
  }
  .pag4-sanfona4-image1 {
    flex: 1;
    width: 40%;
    height: 480px;
  }
  .pag4-sanfona4-container5 {
    flex-direction: column;
  }
  .pag4-sanfona4-text25 {
    width: 100%;
  }
  .pag4-sanfona4-slider {
    height: 933px;
  }
  .pag4-sanfona4-container7 {
    align-items: center;
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .pag4-sanfona4-container8 {
    flex-direction: column;
  }
  .pag4-sanfona4-container9 {
    width: 100%;
  }
  .pag4-sanfona4-image2 {
    width: 100%;
    height: auto;
    position: static;
  }
}
@media(max-width: 767px) {
  .pag4-sanfona4-container3 {
    flex-direction: column;
  }
  .pag4-sanfona4-container4 {
    width: 100%;
  }
  .pag4-sanfona4-image1 {
    flex: 0 0 auto;
    width: 100%;
    height: 420px;
  }
  .pag4-sanfona4-container5 {
    flex-direction: column;
  }
  .pag4-sanfona4-slider {
    height: 990px;
  }
  .pag4-sanfona4-container7 {
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pag4-sanfona4-container9 {
    width: 100%;
  }
  .pag4-sanfona4-image2 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pag4-sanfona4-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag4-sanfona4-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona4-icone-ativo {
    width: 20px;
  }
  .pag4-sanfona4-icone-inativo {
    width: 20px;
  }
  .pag4-sanfona4-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona4-container3 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag4-sanfona4-container4 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag4-sanfona4-image1 {
    width: 100%;
    height: 260px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag4-sanfona4-container5 {
    flex-direction: column;
  }
  .pag4-sanfona4-text25 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag4-sanfona4-slider {
    height: 717px;
  }
  .pag4-sanfona4-container6 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag4-sanfona4-container7 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
    justify-content: flex-start;
  }
  .pag4-sanfona4-container8 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona4-container9 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag4-sanfona4-image2 {
    width: 100%;
    height: auto;
    margin: 0px;
  }
}
