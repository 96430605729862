.pagina5-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-principal-branco);
}
.pagina5-hero {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.pagina5-image1 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  position: absolute;
  box-shadow: 5px 5px 60px 20px #29b9f2;
  object-fit: cover;
}
.pagina5-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1200px;
  min-width: auto;
  align-items: center;
  flex-direction: column;
}
.pagina5-container3 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.pagina5-text10 {
  fill: var(--dl-color-principal-verde);
  color: var(--dl-color-principal-verde);
  font-size: 48px;
  font-style: italic;
  font-weight: 400;
}
.pagina5-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pagina5-text11 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 100%;
}
.pagina5-text12 {
  font-family: "BlauerNue-Bold";
}
.pagina5-text13 {
  font-family: "BlauerNue-Bold";
}
.pagina5-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.pagina5-boto {
  gap: 10px;
  display: flex;
  padding: 20px;
  z-index: 2;
  align-items: center;
  margin-right: var(--dl-space-space-fourunits);
  border-radius: 30px;
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina5-text15 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600px;
  font-stretch: normal;
}
.pagina5-indicaes {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
}
.pagina5-vector1 {
  width: 52px;
  height: 55px;
}
.pagina5-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.pagina5-vector3 {
  width: 52px;
  height: 55px;
}
.pagina5-image2 {
  width: 100%;
  object-fit: cover;
}
.pagina5-container6 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.pagina5-vector4 {
  width: 36px;
  height: 72px;
}
.pagina5-text17 {
  font-weight: 700;
}
.pagina5-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.pagina5-text21 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pagina5-text22 {
  display: inline-block;
}
.pagina5-text24 {
  font-weight: 700;
}
.pagina5-text26 {
  font-weight: 700;
}
.pagina5-text27 {
  font-weight: 700;
}
.pagina5-text29 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina5-text30 {
  display: inline-block;
}
.pagina5-text32 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina5-text34 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina5-text35 {
  font-family: "BlauerNue-Light";
}
.pagina5-text37 {
  font-family: "BlauerNue-Light";
}
.pagina5-text38 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina5-text39 {
  font-family: "BlauerNue-Light";
}
.pagina5-sugestao-conteudo {
  width: 100%;
  height: 360px;
  display: flex;
  padding: 0px;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina5-interno {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: flex-end;
  flex-shrink: 0;
  justify-content: flex-start;
}
.pagina5-texto-ei {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.pagina5-text41 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  text-align: left;
}
.pagina5-text42 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 440px;
  align-self: stretch;
  text-align: left;
  letter-spacing: 0em;
}
.pagina5-button {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  margin-top: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-secundario-rosa);
}
.pagina5-image3 {
  flex: 1;
  right: 0px;
  width: auto;
  bottom: 0px;
  height: 80%;
  margin: auto;
  position: absolute;
  transform: scale(1.4);
  object-fit: cover;
  transform-origin: left bottom;
}
@media(max-width: 1200px) {
  .pagina5-container3 {
    width: auto;
  }
  .pagina5-text11 {
    color: var(--dl-color-principal-branco);
  }
  .pagina5-sugestao-conteudo {
    height: auto;
  }
  .pagina5-interno {
    gap: 0px;
    height: auto;
    padding: 0px;
    flex-direction: row;
  }
  .pagina5-texto-ei {
    margin-right: 0px;
  }
  .pagina5-image3 {
    right: 0px;
  }
}
@media(max-width: 991px) {
  .pagina5-image1 {
    height: 506px;
  }
  .pagina5-container3 {
    width: 100%;
  }
  .pagina5-container4 {
    width: 100%;
  }
  .pagina5-sugestao-conteudo {
    padding: 0px;
  }
  .pagina5-interno {
    flex-direction: column;
  }
  .pagina5-image3 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    position: static;
    transform: scale(1.1);
    transform-origin: center bottom;
  }
}
@media(max-width: 767px) {
  .pagina5-image1 {
    height: 695px;
  }
  .pagina5-container3 {
    width: 100%;
    flex-direction: column;
  }
  .pagina5-container4 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pagina5-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .pagina5-image1 {
    height: 240px;
  }
  .pagina5-container2 {
    gap: var(--dl-space-space-unit);
  }
  .pagina5-container3 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .pagina5-text10 {
    font-size: 16px;
  }
  .pagina5-container4 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    width: 100%;
    height: auto;
  }
  .pagina5-text11 {
    color: var(--dl-color-principal-branco);
    width: auto;
  }
  .pagina5-container5 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .pagina5-boto {
    padding-top: 10px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 10px;
  }
  .pagina5-text15 {
    color: var(--dl-color-secundario-azul-forte);
  }
  .pagina5-indicaes {
    gap: var(--dl-space-space-halfunit);
  }
  .pagina5-vector1 {
    width: 24px;
    height: 24px;
  }
  .pagina5-vector2 {
    width: 24px;
    height: 24px;
  }
  .pagina5-vector3 {
    width: 24px;
    height: 24px;
  }
  .pagina5-container6 {
    gap: var(--dl-space-space-unit);
  }
  .pagina5-vector4 {
    width: 10px;
    height: 19px;
  }
  .pagina5-container7 {
    padding: 0px;
  }
  .pagina5-text38 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pagina5-sugestao-conteudo {
    height: auto;
    padding: 0px;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pagina5-interno {
    gap: var(--dl-space-space-unit);
    height: auto;
    padding: 0px;
    overflow: hidden;
    flex-direction: column;
  }
  .pagina5-texto-ei {
    gap: var(--dl-space-space-unit);
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
  }
  .pagina5-text41 {
    width: 100%;
  }
  .pagina5-text42 {
    color: var(--dl-color-secundario-azul-forte);
    width: 100%;
    letter-spacing: 0em;
  }
  .pagina5-button {
    margin-top: 0px;
    padding-top: 12px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 12px;
    text-decoration: none;
  }
}
