.pag4-sanfona3-container1 {
  flex-direction: column;
}
.pag4-sanfona3-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag4-sanfona3-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag4-sanfona3-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona3-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag4-sanfona3-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag4-sanfona3-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag4-sanfona3-container2:active {
  cursor: grabbing;
}
.pag4-sanfona3-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona3-container4 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: 40%;
  height: 560px;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona3-image1 {
  width: 100%;
  object-fit: cover;
}
.pag4-sanfona3-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pag4-sanfona3-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona3-link {
  text-decoration: none;
}
.pag4-sanfona3-text13 {
  font-weight: 700;
}
.pag4-sanfona3-text15 {
  font-weight: 700;
}
.pag4-sanfona3-text20 {
  font-weight: 700;
}
.pag4-sanfona3-text22 {
  font-weight: 700;
}
.pag4-sanfona3-text27 {
  font-weight: 700;
}
.pag4-sanfona3-text32 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona3-text33 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pag4-sanfona3-text35 {
  font-family: "BlauerNue-Light";
}

@media(max-width: 991px) {
  .pag4-sanfona3-container3 {
    flex-direction: column-reverse;
  }
  .pag4-sanfona3-container4 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }
  .pag4-sanfona3-image1 {
    flex: 1;
    width: 40%;
    border-radius: 0px;
  }
  .pag4-sanfona3-image2 {
    flex: 1;
    width: 40%;
  }
  .pag4-sanfona3-container5 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .pag4-sanfona3-container3 {
    flex-direction: column-reverse;
  }
  .pag4-sanfona3-container4 {
    flex-direction: column;
  }
  .pag4-sanfona3-image1 {
    width: 100%;
  }
  .pag4-sanfona3-image2 {
    width: 100%;
  }
  .pag4-sanfona3-container5 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pag4-sanfona3-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag4-sanfona3-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona3-icone-ativo {
    width: 20px;
  }
  .pag4-sanfona3-icone-inativo {
    width: 20px;
  }
  .pag4-sanfona3-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona3-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .pag4-sanfona3-container4 {
    gap: var(--dl-space-space-halfunit);
  }
  .pag4-sanfona3-image1 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag4-sanfona3-image2 {
    width: 100%;
    height: auto;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag4-sanfona3-container5 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
}
