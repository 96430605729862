.pag3-sanfona1-container1 {
  flex-direction: column;
}
.pag3-sanfona1-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag3-sanfona1-text100 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag3-sanfona1-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag3-sanfona1-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag3-sanfona1-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag3-sanfona1-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag3-sanfona1-container2:active {
  cursor: grabbing;
}
.pag3-sanfona1-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag3-sanfona1-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona1-text104 {
  font-weight: 700;
}
.pag3-sanfona1-text109 {
  font-weight: 700;
}
.pag3-sanfona1-text114 {
  font-weight: 700;
}
.pag3-sanfona1-text116 {
  font-weight: 700;
}
.pag3-sanfona1-image1 {
  flex: 1;
  width: 516px;
  align-self: stretch;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag3-sanfona1-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag3-sanfona1-container6 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: flex-start;
  align-items: flex-end;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  justify-content: flex-start;
  background-color: #C0D99C;
}
.pag3-sanfona1-container7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 56%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.pag3-sanfona1-text119 {
  font-family: "BlauerNue-Light";
}
.pag3-sanfona1-text124 {
  font-weight: 700;
}
.pag3-sanfona1-text127 {
  font-weight: 700;
}
.pag3-sanfona1-text129 {
  font-weight: 700;
}
.pag3-sanfona1-text130 {
  font-weight: 700;
}
.pag3-sanfona1-image2 {
  flex: 1;
  width: auto;
  height: auto;
  object-fit: cover;
}
.pag3-sanfona1-container8 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona1-text132 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 880px;
  font-family: "BlauerNue-Light";
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag3-sanfona1-slider {
  width: 100%;
  cursor: grab;
  height: auto;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag3-sanfona1-slider-slide1 {
  display: flex;
}
.pag3-sanfona1-text135 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona1-text136 {
  display: inline-block;
}
.pag3-sanfona1-text138 {
  font-weight: 700;
}
.pag3-sanfona1-text142 {
  font-weight: 700;
}
.pag3-sanfona1-text144 {
  font-weight: 700;
}
.pag3-sanfona1-text149 {
  font-weight: 700;
}
.pag3-sanfona1-slider-slide2 {
  display: flex;
}
.pag3-sanfona1-text151 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona1-text152 {
  display: inline-block;
}
.pag3-sanfona1-text154 {
  font-weight: 700;
}
.pag3-sanfona1-text158 {
  font-weight: 700;
}
.pag3-sanfona1-slider-slide3 {
  display: flex;
}
.pag3-sanfona1-text160 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona1-text161 {
  display: inline-block;
}
.pag3-sanfona1-text163 {
  font-weight: 700;
}
.pag3-sanfona1-text167 {
  font-weight: 700;
}
.pag3-sanfona1-slider-slide4 {
  display: flex ;
}
.pag3-sanfona1-text169 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona1-text170 {
  display: inline-block;
}
.pag3-sanfona1-text172 {
  font-weight: 700;
}
.pag3-sanfona1-text177 {
  font-weight: 700;
}
.pag3-sanfona1-slider-slide5 {
  display: flex ;
}
.pag3-sanfona1-text183 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona1-text184 {
  display: inline-block;
}
.pag3-sanfona1-text186 {
  font-weight: 700;
}
.pag3-sanfona1-text191 {
  font-weight: 700;
}
.pag3-sanfona1-text192 {
  font-weight: 700;
}
.pag3-sanfona1-text193 {
  font-weight: 700;
}
.pag3-sanfona1-slider-slide6 {
  display: flex ;
}
.pag3-sanfona1-text195 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona1-text196 {
  display: inline-block;
}
.pag3-sanfona1-text198 {
  font-weight: 700;
}
.pag3-sanfona1-text203 {
  font-weight: 700;
}
.pag3-sanfona1-slider-pagination {
  display: block;
}
.pag3-sanfona1-slider-button-prev {
  display: none;
}
.pag3-sanfona1-slider-button-next {
  display: none;
}
.pag3-sanfona1-text207 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}

@media(max-width: 1200px) {
  .pag3-sanfona1-image1 {
    align-self: flex-start;
  }
  .pag3-sanfona1-container6 {
    gap: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .pag3-sanfona1-image2 {
    top: auto;
    right: 0px;
    width: 40%;
    bottom: 0px;
  }
  .pag3-sanfona1-slider {
    height: 820px;
  }
}
@media(max-width: 991px) {
  .pag3-sanfona1-container3 {
    flex-direction: column;
  }
  .pag3-sanfona1-container4 {
    width: 100%;
  }
  .pag3-sanfona1-image1 {
    width: 100%;
  }
  .pag3-sanfona1-container6 {
    align-items: center;
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .pag3-sanfona1-container7 {
    width: 100%;
    padding-bottom: 0px;
  }
  .pag3-sanfona1-image2 {
    width: 60%;
    height: auto;
    position: static;
  }
  .pag3-sanfona1-text132 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .pag3-sanfona1-container3 {
    flex-direction: column;
  }
  .pag3-sanfona1-container4 {
    width: 100%;
  }
  .pag3-sanfona1-image1 {
    width: 100%;
  }
  .pag3-sanfona1-container6 {
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pag3-sanfona1-container7 {
    width: 100%;
  }
  .pag3-sanfona1-image2 {
    width: 100%;
  }
  .pag3-sanfona1-text132 {
    width: auto;
  }
  .pag3-sanfona1-slider {
    height: 990px;
  }
}
@media(max-width: 479px) {
  .pag3-sanfona1-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag3-sanfona1-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag3-sanfona1-icone-ativo {
    width: 20px;
  }
  .pag3-sanfona1-icone-inativo {
    width: 20px;
  }
  .pag3-sanfona1-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag3-sanfona1-container3 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag3-sanfona1-container4 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag3-sanfona1-image1 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag3-sanfona1-container5 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag3-sanfona1-container6 {
    gap: var(--dl-space-space-twounits);
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: 0px;
    justify-content: flex-start;
  }
  .pag3-sanfona1-container7 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag3-sanfona1-image2 {
    width: 100%;
    margin: 0px;
  }
  .pag3-sanfona1-text132 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag3-sanfona1-slider {
    height: 684px;
  }
}
