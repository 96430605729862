.pag1-sanfona2-container1 {
  flex-direction: column;
}
.pag1-sanfona2-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  justify-content: space-between;
}
.pag1-sanfona2-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag1-sanfona2-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona2-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag1-sanfona2-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag1-sanfona2-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag1-sanfona2-container2:active {
  cursor: grabbing;
}
.pag1-sanfona2-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona2-image1 {
  flex: 1;
  width: auto;
  height: 100%;
  object-fit: cover;
}
.pag1-sanfona2-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  overflow: hidden;
  position: relative;
  background: linear-gradient(105deg, #29B9F2 13.21%, #0040CF 106.92%);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag1-sanfona2-image2 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  opacity: 0.4;
  z-index: 100;
  position: absolute;
  object-fit: cover;
  background-blend-mode: screen;
}
.pag1-sanfona2-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag1-sanfona2-image3 {
  flex: 1;
  z-index: 2;
  object-fit: cover;
  margin-left: -60px;
}
.pag1-sanfona2-container6 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona2-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pag1-sanfona2-container7 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 580px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag1-sanfona2-link {
  text-decoration: none;
}
.pag1-sanfona2-text15 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Thin";
}
.pag1-sanfona2-text17 {
  font-family: "BlauerNue-Bold";
}
.pag1-sanfona2-text19 {
  display: inline-block;
}
.pag1-sanfona2-text25 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag1-sanfona2-text26 {
  width: 585px;
  display: inline-block;
}
.pag1-sanfona2-text30 {
  font-weight: 700;
}
.pag1-sanfona2-text34 {
  display: inline-block;
}
.pag1-sanfona2-text35 {
  font-weight: 700;
}
.pag1-sanfona2-text37 {
  display: inline-block;
}

@media(max-width: 1200px) {
  .pag1-sanfona2-image4 {
    flex: 1;
  }
  .pag1-sanfona2-container7 {
    flex: 1;
    width: 100%;
  }
}
@media(max-width: 991px) {
  .pag1-sanfona2-container3 {
    flex-direction: column;
  }
  .pag1-sanfona2-image1 {
    width: 100%;
  }
  .pag1-sanfona2-container4 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .pag1-sanfona2-image3 {
    width: 100%;
    align-self: center;
  }
  .pag1-sanfona2-container6 {
    flex-direction: column-reverse;
  }
  .pag1-sanfona2-text26 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pag1-sanfona2-container1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag1-sanfona2-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag1-sanfona2-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag1-sanfona2-icone-ativo {
    width: 20px;
  }
  .pag1-sanfona2-icone-inativo {
    width: 20px;
  }
  .pag1-sanfona2-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag1-sanfona2-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag1-sanfona2-image1 {
    width: 100%;
  }
  .pag1-sanfona2-container4 {
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
  }
  .pag1-sanfona2-container5 {
    gap: var(--dl-space-space-unit);
  }
  .pag1-sanfona2-image3 {
    width: 100%;
    margin: 0px;
  }
  .pag1-sanfona2-container6 {
    gap: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .pag1-sanfona2-container7 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag1-sanfona2-text26 {
    width: 100%;
  }
}
