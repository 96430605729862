.home-container10 {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero-banner {
  width: 100%;
  height: 80vh;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
}
.home-fundo1 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  background-size: cover;
  background-image: url("/Pagina Home/hero%20fundo.png");
}
.home-inner {
  flex: 0 0 auto;
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: flex-start;
}
.home-interno1 {
  gap: 40px;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: stretch;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.home-interno1:focus {
  transform: scale(1.1);
}
.home-ellipse92 {
  top: 6px;
  left: -218px;
  width: 843px;
  height: 250px;
  position: absolute;
}
.home-frame2 {
  gap: 20px;
  width: 541.107421875px;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.home-text10 {
  fill: var(--dl-color-principal-verde);
  color: var(--dl-color-principal-verde);
  height: auto;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-weight: 800px;
  font-stretch: normal;
}
.home-text14 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 380px;
  height: auto;
  font-style: normal;
  text-align: left;
  font-weight: 500px;
  font-stretch: normal;
}
.home-navlink1 {
  display: contents;
}
.home-frame1000003354 {
  gap: 10px;
  display: flex;
  padding: 20px;
  z-index: 2;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-principal-verde);
}
.home-text20 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 600px;
  font-stretch: normal;
}
.home-seo1 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
}
.home-fundo2 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: var(--dl-color-principal-branco);
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-interno2 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 3;
  overflow: hidden;
  align-items: flex-start;
  padding-top: 170px;
  padding-left: 132px;
  border-radius: 30px;
  padding-right: 132px;
  padding-bottom: 170px;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/Pagina Home/fundo-secao2-1400w.png");
}
.home-group1000003551 {
  width: 432px;
  height: 220px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
}
.home-text22 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  height: auto;
  font-style: normal;
  text-align: left;
  font-weight: 600px;
  font-stretch: normal;
}
.home-text23 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  height: auto;
  font-style: normal;
  text-align: left;
  font-weight: 700px;
  font-stretch: normal;
}
.home-text24 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 628px;
  font-style: normal;
  text-align: left;
  font-weight: 500px;
  font-stretch: normal;
}
.home-text25 {
  font-weight: 700;
}
.home-text29 {
  font-weight: 700;
}
.home-textura {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  mix-blend-mode: color-burn;
  background-size: cover;
  background-image: url("/Pagina Home/textura-secao2-1500w.png");
  background-blend-mode: screen;
}
.home-seo2 {
  width: 100%;
  height: 360px;
  display: flex;
  overflow: visible;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  background-size: cover;
  justify-content: center;
  background-image: url("/Pagina Home/experiencia%20imersiva%20fundo-400h.png");
}
.home-interno3 {
  gap: -97px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-twounits);
}
.home-image1 {
  width: 422px;
  display: none;
  object-fit: cover;
}
.home-texto-ei1 {
  gap: 32px;
  width: 822px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.home-text34 {
  fill: var(--dl-color-secundario-branco);
  color: var(--dl-color-secundario-branco);
  height: auto;
  text-align: left;
}
.home-text36 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 660px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Montserrat;
  font-weight: 500px;
  font-stretch: normal;
}
.home-container12 {
  flex: 0 0 auto;
  width: 800px;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: flex-start;
}
.home-fundo3 {
  width: 100%;
  object-fit: cover;
  mix-blend-mode: color-burn;
}
.home-oculos1 {
  top: 20px;
  left: 0px;
  right: 0px;
  width: 550px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  transition: 0.3s;
}
.home-oculos1:hover {
  transform: rotate(-5deg) scale(1.1);
}
.home-seo3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: var(--dl-color-principal-branco);
}
.home-interno4 {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 3;
  overflow: hidden;
  max-width: 1200px;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
}
.home-seo4 {
  width: 100%;
  height: 360px;
  display: flex;
  overflow: visible;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  background-size: cover;
  justify-content: center;
  background-image: url("/Pagina Home/experiencia%20imersiva%20fundo-400h.png");
}
.home-interno5 {
  gap: -97px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-twounits);
}
.home-texto-ei2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.home-text37 {
  fill: var(--dl-color-secundario-branco);
  color: var(--dl-color-secundario-branco);
  height: auto;
  text-align: left;
}
.home-text41 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 680px;
  align-self: stretch;
  text-align: left;
  letter-spacing: 0em;
}
.home-text42 {
  font-weight: 700;
}
.home-container13 {
  flex: 0 0 auto;
  width: 673px;
  height: 100%;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: flex-start;
}
.home-fundo4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: screen;
  background-position: center;
}
.home-oculos2 {
  top: -90px;
  left: 0px;
  right: 0px;
  width: 500px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  transition: 0.3s;
}
.home-oculos2:hover {
  transform: scale(1.1);
}
.home-seo5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-container14 {
  gap: 32px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: grid;
  align-items: flex-start;
  grid-row-gap: 80px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.home-container15 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.home-container15:hover {
  cursor: pointer;
  transform: scale(1.025);
}
.home-image2 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius12);
}
.home-container16 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
}
.home-container17 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color: var(--dl-color-principal-verde);
}
.home-text44 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  font-size: 32px;
  font-style: italic;
  font-family: "Montserrat";
}
.home-text45 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.home-text47 {
  font-weight: 700;
}
.home-container18 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.home-container18:hover {
  cursor: pointer;
  transform: scale(1.025);
}
.home-image3 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius12);
}
.home-container19 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
}
.home-container20 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color: var(--dl-color-principal-verde);
}
.home-text48 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  font-size: 32px;
  font-style: italic;
  font-family: "Montserrat";
}
.home-text49 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.home-text50 {
  font-weight: 700;
}
.home-container21 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.home-container21:hover {
  cursor: pointer;
  transform: scale(1.025);
}
.home-image4 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius12);
}
.home-container22 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
}
.home-container23 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color: var(--dl-color-principal-verde);
}
.home-text53 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  font-size: 32px;
  font-style: italic;
  font-family: "Montserrat";
}
.home-text54 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.home-text55 {
  font-weight: 700;
}
.home-container24 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  transform: scale();
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.home-container24:hover {
  cursor: point;
  transform: scale(1.025);
}
.home-image5 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius12);
}
.home-container25 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
}
.home-container26 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color: var(--dl-color-principal-verde);
}
.home-text57 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  font-size: 32px;
  font-style: italic;
  font-family: "Montserrat";
}
.home-text58 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.home-text59 {
  font-weight: 700;
}
.home-container27 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  transform: scale();
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.home-container27:hover {
  cursor: point;
  transform: scale(1.025);
}
.home-image6 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius12);
}
.home-container28 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
}
.home-container29 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color: var(--dl-color-principal-verde);
}
.home-text62 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  font-size: 32px;
  font-style: italic;
  font-family: "Montserrat";
}
.home-text63 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.home-text64 {
  font-weight: 700;
}
.home-container30 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.home-container30:hover {
  cursor: point;
  transform: scale(1.025);
}
.home-image7 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius12);
}
.home-container31 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
}
.home-container32 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color: var(--dl-color-principal-verde);
}
.home-text66 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  font-size: 32px;
  font-style: italic;
  font-family: "Montserrat";
}
.home-text67 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  font-weight: 700;
}
.home-seo6 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.home-container33 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-container34 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text68 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.home-text70 {
  font-weight: 700;
}
.home-form {
  gap: var(--dl-space-space-unit);
  width: 600px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-textinput1 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-container35 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-textinput2 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-select {
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-textinput3 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-textinput4 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-textinput5 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-textinput6 {
  width: 100%;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-container36 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.home-container37 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-criatividade-unabranco);
}
.home-checkbox {
  width: 32px;
  height: 32px;
  outline: 2px solid white;
  outline-offset: -2px;
}
.home-text72 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.home-button {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-secundario-rosa);
}
.home-image8 {
  top: 0px;
  right: 0px;
  width: 40%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  object-fit: cover;
  border-radius: 0px;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}
@media(max-width: 1200px) {
  .home-fundo1 {
    background-position: center;
  }
  .home-interno2 {
    gap: 80px;
    padding-top: 120px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 120px;
    justify-content: center;
  }
  .home-text24 {
    width: 520px;
  }
  .home-texto-ei1 {
    width: 740px;
  }
  .home-texto-ei2 {
    margin-right: 0px;
  }
  .home-container13 {
    width: 540px;
  }
  .home-oculos2 {
    top: -70px;
    width: 400px;
  }
}
@media(max-width: 991px) {
  .home-interno2 {
    flex-direction: column;
  }
  .home-container33 {
    align-items: flex-start;
  }
  .home-image8 {
    display: none;
  }
}
@media(max-width: 767px) {
  .home-interno2 {
    flex-direction: column;
  }
  .home-text24 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-hero-banner {
    height: 586px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-fundo1 {
    height: 100%;
    background-size: cover;
    background-image: url("/Pagina Home/hero%20fundo%20mobile.png");
  }
  .home-interno1 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
  }
  .home-ellipse92 {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    margin: auto;
  }
  .home-frame2 {
    gap: var(--dl-space-space-unit);
    width: auto;
  }
  .home-text10 {
    color: var(--dl-color-principal-verde);
    font-style: normal;
  }
  .home-text14 {
    color: var(--dl-color-principal-branco);
    width: 258px;
    font-style: normal;
  }
  .home-text16 {
    font-weight: 700;
  }
  .home-text18 {
    font-weight: 700;
  }
  .home-frame1000003354 {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
  }
  .home-seo1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container11 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-interno2 {
    gap: var(--dl-space-space-unit);
    margin: 0px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .home-group1000003551 {
    width: auto;
    height: auto;
  }
  .home-text23 {
    color: var(--dl-color-principal-branco);
    font-style: normal;
  }
  .home-text24 {
    width: 100%;
  }
  .home-seo2 {
    height: auto;
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-interno3 {
    gap: 0;
    height: fit-content;
    z-index: 5;
    padding-left: 0px;
    flex-direction: column;
  }
  .home-image1 {
    width: 362px;
    height: 300px;
    display: flex;
    z-index: 10;
    margin-top: -96px;
  }
  .home-texto-ei1 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .home-text34 {
    width: 100%;
  }
  .home-text36 {
    width: 100%;
  }
  .home-container12 {
    display: none;
    align-self: center;
  }
  .home-seo3 {
    padding-top: var(--dl-space-space-unit);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-interno4 {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .home-seo4 {
    height: auto;
    padding: 0px;
  }
  .home-interno5 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column-reverse;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-texto-ei2 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-text37 {
    color: var(--dl-color-secundario-branco);
    width: 100%;
  }
  .home-text41 {
    width: 100%;
  }
  .home-container13 {
    width: 100%;
    height: 203px;
  }
  .home-fundo4 {
    width: 100%;
  }
  .home-oculos2 {
    width: 294px;
    height: 267px;
  }
  .home-seo5 {
    padding: var(--dl-space-space-twounits);
  }
  .home-container14 {
    gap: var(--dl-space-space-threeunits);
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .home-container15 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-container16 {
    gap: var(--dl-space-space-unit);
  }
  .home-container17 {
    width: 28px;
    padding: 0px;
    position: relative;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-text44 {
    font-size: 18px;
    text-align: center;
    line-height: 1;
  }
  .home-text45 {
    color: var(--dl-color-principal-azul);
  }
  .home-text47 {
    font-weight: 700;
  }
  .home-container18 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-container19 {
    gap: var(--dl-space-space-unit);
  }
  .home-container20 {
    width: 28px;
    padding: 0px;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  .home-text48 {
    font-size: 18px;
    text-align: center;
  }
  .home-text49 {
    color: var(--dl-color-principal-azul);
  }
  .home-text50 {
    font-weight: 700;
  }
  .home-container21 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-container22 {
    gap: var(--dl-space-space-unit);
  }
  .home-container23 {
    width: 28px;
    padding: 0px;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  .home-text53 {
    font-size: 18px;
    text-align: center;
  }
  .home-text54 {
    color: var(--dl-color-principal-azul);
  }
  .home-text55 {
    font-weight: 700;
  }
  .home-container24 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-container25 {
    gap: var(--dl-space-space-unit);
  }
  .home-container26 {
    width: 28px;
    padding: 0px;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  .home-text57 {
    font-size: 18px;
    text-align: center;
  }
  .home-text58 {
    color: var(--dl-color-principal-azul);
  }
  .home-text59 {
    font-weight: 700;
  }
  .home-container27 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-container28 {
    gap: var(--dl-space-space-unit);
  }
  .home-container29 {
    width: 28px;
    padding: 0px;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  .home-text62 {
    font-size: 18px;
    text-align: center;
  }
  .home-container30 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-container31 {
    gap: var(--dl-space-space-unit);
  }
  .home-container32 {
    width: 28px;
    padding: 0px;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  .home-text66 {
    font-size: 18px;
    text-align: center;
  }
  .home-seo6 {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container33 {
    padding-left: 0px;
  }
  .home-container34 {
    width: 100%;
  }
  .home-text68 {
    color: var(--dl-color-principal-azul);
    font-size: 26px;
    line-height: 28px;
  }
  .home-form {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-container36 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-image8 {
    width: 100%;
    display: none;
    position: static;
    border-radius: var(--dl-radius-radius-radius30);
  }
}
