.pag4-sanfona6-container1 {
  flex-direction: column;
}
.pag4-sanfona6-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag4-sanfona6-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag4-sanfona6-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona6-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag4-sanfona6-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag4-sanfona6-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag4-sanfona6-container2:active {
  cursor: grabbing;
}
.pag4-sanfona6-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-principal-verde);
}
.pag4-sanfona6-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona6-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 100px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag4-sanfona6-container6 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.pag4-sanfona6-image1 {
  width: 200px;
  object-fit: cover;
}
.pag4-sanfona6-image2 {
  width: 200px;
  object-fit: cover;
}
.pag4-sanfona6-container7 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.pag4-sanfona6-image3 {
  width: 200px;
  object-fit: cover;
}
.pag4-sanfona6-image4 {
  width: 200px;
  object-fit: cover;
}
.pag4-sanfona6-container8 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona6-container9 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona6-text17 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text19 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text21 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text23 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text25 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text27 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-image5 {
  flex: 1;
  width: 40%;
  object-fit: cover;
  transform-origin: bottom;
}
.pag4-sanfona6-text29 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pag4-sanfona6-text31 {
  font-family: "BlauerNue-Light";
}
.pag4-sanfona6-text32 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 100%;
  display: inline-block;
}
.pag4-sanfona6-text34 {
  font-weight: 700;
}
.pag4-sanfona6-text38 {
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text40 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 100%;
  display: inline-block;
}
.pag4-sanfona6-text42 {
  font-weight: 700;
}
.pag4-sanfona6-text44 {
  font-weight: 700;
}
.pag4-sanfona6-text50 {
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text52 {
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text54 {
  font-weight: 700;
  text-decoration: underline;
}
.pag4-sanfona6-text56 {
  display: inline-block;
}

@media(max-width: 991px) {
  .pag4-sanfona6-container4 {
    flex: 1;
    width: 50%;
  }
  .pag4-sanfona6-container8 {
    flex-direction: column;
  }
  .pag4-sanfona6-container9 {
    width: 100%;
  }
  .pag4-sanfona6-image5 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .pag4-sanfona6-text32 {
    width: 100%;
  }
  .pag4-sanfona6-text40 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .pag4-sanfona6-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .pag4-sanfona6-container4 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
  }
  .pag4-sanfona6-container6 {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .pag4-sanfona6-image1 {
    width: 100%;
  }
  .pag4-sanfona6-image2 {
    width: 100%;
  }
  .pag4-sanfona6-container7 {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .pag4-sanfona6-image3 {
    width: 100%;
  }
  .pag4-sanfona6-image4 {
    width: 100%;
  }
  .pag4-sanfona6-container8 {
    flex-direction: column;
  }
  .pag4-sanfona6-container9 {
    width: 100%;
  }
  .pag4-sanfona6-image5 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
}
@media(max-width: 479px) {
  .pag4-sanfona6-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag4-sanfona6-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona6-icone-ativo {
    width: 20px;
  }
  .pag4-sanfona6-icone-inativo {
    width: 20px;
  }
  .pag4-sanfona6-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona6-container3 {
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
  }
  .pag4-sanfona6-container4 {
    width: 100%;
  }
  .pag4-sanfona6-container5 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .pag4-sanfona6-image1 {
    width: 100px;
    height: auto;
  }
  .pag4-sanfona6-image2 {
    width: 100%;
    height: auto;
  }
  .pag4-sanfona6-image3 {
    width: 100px;
  }
  .pag4-sanfona6-image4 {
    width: 100%;
  }
  .pag4-sanfona6-container8 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag4-sanfona6-container9 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag4-sanfona6-image5 {
    flex: 1;
    width: 100%;
    height: auto;
  }
  .pag4-sanfona6-text32 {
    width: 100%;
  }
  .pag4-sanfona6-text40 {
    width: 100%;
  }
}
