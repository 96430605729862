.pag5-sanfona2-container1 {
  flex-direction: column;
}
.pag5-sanfona2-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag5-sanfona2-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag5-sanfona2-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag5-sanfona2-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag5-sanfona2-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag5-sanfona2-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag5-sanfona2-container2:active {
  cursor: grabbing;
}
.pag5-sanfona2-container3 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag5-sanfona2-text13 {
  font-weight: 700;
}
.pag5-sanfona2-slider {
  width: 100%;
  cursor: grab;
  height: 542px;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag5-sanfona2-slider-slide1 {
  display: flex ;
}
.pag5-sanfona2-text15 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona2-text16 {
  display: inline-block;
}
.pag5-sanfona2-text18 {
  font-weight: 700;
}
.pag5-sanfona2-text19 {
  font-weight: 700;
}
.pag5-sanfona2-text21 {
  font-weight: 700;
}
.pag5-sanfona2-slider-slide2 {
  display: flex ;
}
.pag5-sanfona2-text22 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona2-text23 {
  display: inline-block;
}
.pag5-sanfona2-text28 {
  font-weight: 700;
}
.pag5-sanfona2-slider-slide3 {
  display: flex ;
}
.pag5-sanfona2-text31 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona2-text32 {
  display: inline-block;
}
.pag5-sanfona2-text37 {
  font-weight: 700;
}
.pag5-sanfona2-slider-slide4 {
  display: flex ;
}
.pag5-sanfona2-text39 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona2-text40 {
  display: inline-block;
}
.pag5-sanfona2-text45 {
  font-weight: 700;
}
.pag5-sanfona2-text47 {
  font-weight: 700;
}
.pag5-sanfona2-slider-slide5 {
  display: flex ;
}
.pag5-sanfona2-text49 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona2-text50 {
  display: inline-block;
}
.pag5-sanfona2-slider-pagination {
  display: block;
}
.pag5-sanfona2-slider-button-prev {
  display: none;
}
.pag5-sanfona2-slider-button-next {
  display: none;
}

@media(max-width: 1200px) {
  .pag5-sanfona2-slider {
    height: 560px;
  }
}
@media(max-width: 991px) {
  .pag5-sanfona2-container3 {
    flex-direction: column;
  }
  .pag5-sanfona2-slider {
    height: 606px;
  }
}
@media(max-width: 767px) {
  .pag5-sanfona2-container3 {
    flex-direction: column;
  }
  .pag5-sanfona2-slider {
    height: 814px;
  }
}
@media(max-width: 479px) {
  .pag5-sanfona2-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag5-sanfona2-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag5-sanfona2-icone-ativo {
    width: 20px;
  }
  .pag5-sanfona2-icone-inativo {
    width: 20px;
  }
  .pag5-sanfona2-container2 {
    gap: var(--dl-space-space-twounits);
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag5-sanfona2-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag5-sanfona2-text11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag5-sanfona2-slider {
    height: 573px;
  }
}
