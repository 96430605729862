.pag4-sanfona5-container1 {
  flex-direction: column;
}
.pag4-sanfona5-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag4-sanfona5-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag4-sanfona5-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag4-sanfona5-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag4-sanfona5-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag4-sanfona5-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag4-sanfona5-container2:active {
  cursor: grabbing;
}
.pag4-sanfona5-container3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag4-sanfona5-slider {
  width: 100%;
  cursor: grab;
  height: auto;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag4-sanfona5-slider-slide1 {
  display: flex;
}
.pag4-sanfona5-text11 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona5-text12 {
  display: inline-block;
}
.pag4-sanfona5-text14 {
  font-weight: 700;
}
.pag4-sanfona5-text15 {
  font-weight: 700;
}
.pag4-sanfona5-text18 {
  font-weight: 700;
}
.pag4-sanfona5-slider-slide2 {
  display: flex;
}
.pag4-sanfona5-text20 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona5-text21 {
  display: inline-block;
}
.pag4-sanfona5-text22 {
  font-weight: 700;
}
.pag4-sanfona5-text24 {
  font-weight: 700;
}
.pag4-sanfona5-slider-slide3 {
  display: flex;
}
.pag4-sanfona5-text26 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona5-text27 {
  display: inline-block;
}
.pag4-sanfona5-text29 {
  font-weight: 700;
}
.pag4-sanfona5-text33 {
  font-weight: 700;
}
.pag4-sanfona5-text35 {
  font-weight: 700;
}
.pag4-sanfona5-slider-slide4 {
  display: flex ;
}
.pag4-sanfona5-text37 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona5-text38 {
  display: inline-block;
}
.pag4-sanfona5-text42 {
  font-weight: 700;
}
.pag4-sanfona5-text45 {
  font-weight: 700;
}
.pag4-sanfona5-text47 {
  font-weight: 700;
}
.pag4-sanfona5-slider-slide5 {
  display: flex ;
}
.pag4-sanfona5-text52 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona5-text53 {
  display: inline-block;
}
.pag4-sanfona5-text55 {
  font-weight: 700;
}
.pag4-sanfona5-text60 {
  font-weight: 700;
}
.pag4-sanfona5-text62 {
  font-weight: 700;
}
.pag4-sanfona5-slider-slide6 {
  display: flex ;
}
.pag4-sanfona5-text64 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag4-sanfona5-text65 {
  display: inline-block;
}
.pag4-sanfona5-text67 {
  font-weight: 700;
}
.pag4-sanfona5-text68 {
  font-weight: 700;
}
.pag4-sanfona5-text71 {
  font-weight: 700;
}
.pag4-sanfona5-slider-pagination {
  display: block;
}
.pag4-sanfona5-slider-button-prev {
  display: none;
}
.pag4-sanfona5-slider-button-next {
  display: none;
}

@media(max-width: 1200px) {
  .pag4-sanfona5-slider {
    height: 679px;
  }
}
@media(max-width: 991px) {
  .pag4-sanfona5-container3 {
    flex-direction: column;
  }
  .pag4-sanfona5-slider {
    height: 933px;
  }
}
@media(max-width: 767px) {
  .pag4-sanfona5-container3 {
    flex-direction: column;
  }
  .pag4-sanfona5-slider {
    height: 990px;
  }
}
@media(max-width: 479px) {
  .pag4-sanfona5-topo {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag4-sanfona5-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona5-icone-ativo {
    width: 20px;
  }
  .pag4-sanfona5-icone-inativo {
    width: 20px;
  }
  .pag4-sanfona5-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag4-sanfona5-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag4-sanfona5-slider {
    height: 723px;
  }
}
