.modelo-slide-link-container {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  overflow: visible;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.modelo-slide-link-interno {
  width: 100%;
  height: 640px;
  display: flex;
  z-index: 3;
  overflow: hidden;
  max-width: 1200px;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  align-items: center;
  border-radius: 30px;
  flex-direction: row;
  background-color: var(--dl-color-principal-branco);
}
.modelo-slide-link-image-desktop {
  width: 500px;
  height: 100%;
  object-fit: cover;
}
.modelo-slide-link-image-mobile {
  width: 500px;
  height: 100%;
  object-fit: cover;
}
.modelo-slide-link-frame1000003600 {
  gap: 32px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.modelo-slide-link-image {
  width: 40px;
  object-fit: cover;
}
.modelo-slide-link-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 100%;
  height: auto;
}
.modelo-slide-link-text11 {
  color: rgb(23, 23, 23);
  height: auto;
  align-self: stretch;
  text-align: left;
  text-decoration: none;
}
.modelo-slide-link-indicaes {
  gap: 19px;
  display: none;
  position: relative;
  margin-top: auto;
  align-items: center;
}
.modelo-slide-link-djctql1 {
  width: 52px;
  height: 55px;
  border-radius: 6px;
}
.modelo-slide-link-vector1 {
  width: 52px;
  height: 55px;
}
.modelo-slide-link-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.modelo-slide-link-vector3 {
  width: 52px;
  height: 55px;
}
.modelo-slide-link-text12 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.modelo-slide-link-text13 {
  display: inline-block;
}
.modelo-slide-link-text15 {
  font-weight: 700;
}
.modelo-slide-linkroot-class-name {
  height: 100%;
}
.modelo-slide-linkroot-class-name1 {
  height: 100%;
}


















.modelo-slide-linkroot-class-name20 {
  height: 100%;
}
.modelo-slide-linkroot-class-name21 {
  height: 100%;
}
@media(max-width: 1200px) {
  .modelo-slide-link-container {
    padding: var(--dl-space-space-twounits);
  }
  .modelo-slide-link-interno {
    height: 100%;
  }
  .modelo-slide-link-image-desktop {
    height: 100%;
  }
  .modelo-slide-link-image-mobile {
    height: 100%;
  }
}
@media(max-width: 991px) {
  .modelo-slide-link-image-desktop {
    width: 40%;
  }
}
@media(max-width: 767px) {
  .modelo-slide-link-container {
    height: 100%;
  }
  .modelo-slide-link-interno {
    flex-direction: column;
  }
  .modelo-slide-link-image-mobile {
    width: 100%;
    height: 400px;
  }
  .modelo-slide-link-frame1000003600 {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .modelo-slide-link-container {
    height: 70vh;
    padding: var(--dl-space-space-unit);
  }
  .modelo-slide-link-interno {
    height: 100%;
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
  }
  .modelo-slide-link-image-desktop {
    flex: 0 0 auto;
    width: 100%;
    height: 30%;
  }
  .modelo-slide-link-image-mobile {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
  .modelo-slide-link-frame1000003600 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    height: 100%;
    padding: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .modelo-slide-link-image {
    width: 32px;
  }
  .modelo-slide-linkroot-class-name {
    height: auto;
  }
  .modelo-slide-linkroot-class-name1 {
    height: auto;
  }
  .modelo-slide-linkroot-class-name2 {
    height: auto;
  }
  .modelo-slide-linkroot-class-name3 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name4 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name5 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name6 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name7 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name8 {
    top: 0px;
    left: 0px;
    height: 100%;
    position: 0px;
  }
  .modelo-slide-linkroot-class-name9 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name10 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name11 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name12 {
    top: 0px;
    left: 0px;
    height: 100%;
    position: 0px;
  }
  .modelo-slide-linkroot-class-name13 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name14 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name15 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name16 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name17 {
    top: 0px;
    left: 0px;
    height: 100%;
    position: 0px;
  }
  .modelo-slide-linkroot-class-name18 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name19 {
    height: 100%;
  }
  .modelo-slide-linkroot-class-name20 {
    height: auto;
  }
  .modelo-slide-linkroot-class-name21 {
    height: auto;
  }
}
