.pag1-sanfona5-container1 {
  flex-direction: column;
}
.pag1-sanfona5-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag1-sanfona5-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag1-sanfona5-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona5-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag1-sanfona5-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag1-sanfona5-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag1-sanfona5-container2:active {
  cursor: grabbing;
}
.pag1-sanfona5-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.pag1-sanfona5-image1 {
  width: 80%;
  object-fit: cover;
}
.pag1-sanfona5-image2 {
  top: auto;
  right: 23px;
  width: 260px;
  bottom: 300px;
  z-index: 100;
  position: absolute;
  object-fit: cover;
}
.pag1-sanfona5-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  right: 0px;
  width: 590px;
  bottom: var(--dl-space-space-fourunits);
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: absolute;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius30);
  flex-direction: column;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag1-sanfona5-link1 {
  text-decoration: none;
}
.pag1-sanfona5-container5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius30);
  background-color: var(--dl-color-principal-verde);
}
.pag1-sanfona5-container6 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag1-sanfona5-container7 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 100px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag1-sanfona5-link2 {
  text-decoration: none;
}
.pag1-sanfona5-container8 {
  gap: 4px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona5-link3 {
  text-decoration: none;
}
.pag1-sanfona5-link4 {
  text-decoration: none;
}
.pag1-sanfona5-image3 {
  flex: 1;
  width: 420px;
  object-fit: cover;
}
.pag1-sanfona5-text14 {
  width: 510px;
  display: inline-block;
}
.pag1-sanfona5-text16 {
  font-weight: 700;
}
.pag1-sanfona5-text21 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag1-sanfona5-text25 {
  font-weight: 700;
}
.pag1-sanfona5-text28 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  height: auto;
  display: inline-block;
}
.pag1-sanfona5-text29 {
  font-family: "BlauerNue-Bold";
  font-weight: 700;
}
.pag1-sanfona5-text31 {
  display: inline-block;
}
.pag1-sanfona5-text34 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag1-sanfona5-text35 {
  width: auto;
  display: inline-block;
}
.pag1-sanfona5-text36 {
  color: var(--dl-color-principal-azul);
  width: auto;
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
}
.pag1-sanfona5-text37 {
  width: auto;
  display: inline-block;
}
.pag1-sanfona5-text39 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag1-sanfona5-text41 {
  display: inline-block;
}

@media(max-width: 1200px) {
  .pag1-sanfona5-container8 {
    flex-wrap: wrap;
  }
}
@media(max-width: 991px) {
  .pag1-sanfona5-image2 {
    right: 113px;
    width: 167px;
    height: 153px;
  }
  .pag1-sanfona5-container6 {
    flex: 1;
    width: 50%;
  }
  .pag1-sanfona5-image3 {
    flex: 1;
    width: 50%;
  }
  .pag1-sanfona5-text14 {
    width: 100%;
  }
  .pag1-sanfona5-text35 {
    width: auto;
  }
  .pag1-sanfona5-text36 {
    color: var(--dl-color-principal-azul);
    width: auto;
    font-weight: 700;
    text-decoration: underline;
  }
  .pag1-sanfona5-text37 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .pag1-sanfona5-container3 {
    flex-direction: column;
  }
  .pag1-sanfona5-image1 {
    width: 100%;
  }
  .pag1-sanfona5-container4 {
    width: 100%;
    position: static;
  }
  .pag1-sanfona5-container5 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .pag1-sanfona5-container6 {
    width: 100%;
  }
  .pag1-sanfona5-image3 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pag1-sanfona5-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag1-sanfona5-text10 {
    color: var(--dl-color-principal-azul);
  }
  .pag1-sanfona5-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag1-sanfona5-icone-ativo {
    width: 20px;
  }
  .pag1-sanfona5-icone-inativo {
    width: 20px;
  }
  .pag1-sanfona5-container2 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag1-sanfona5-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag1-sanfona5-image1 {
    width: 100%;
  }
  .pag1-sanfona5-image2 {
    right: -19px;
    width: 121px;
    bottom: 183px;
    height: 104px;
  }
  .pag1-sanfona5-container4 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    padding: var(--dl-space-space-oneandhalfunits);
    position: static;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag1-sanfona5-container5 {
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
  }
  .pag1-sanfona5-container6 {
    width: 100%;
  }
  .pag1-sanfona5-container7 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .pag1-sanfona5-image3 {
    width: 100%;
  }
  .pag1-sanfona5-text14 {
    width: 100%;
  }
  .pag1-sanfona5-text28 {
    width: 100%;
  }
  .pag1-sanfona5-text35 {
    width: 100%;
  }
  .pag1-sanfona5-text36 {
    width: 100%;
  }
  .pag1-sanfona5-text37 {
    width: 100%;
  }
}
