.home-sanfona1-container1 {
  border: none;
  flex-direction: column;
  background-color: transparent;
}
.home-sanfona1-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-bottom: var(--dl-space-space-unit);
}
.home-sanfona1-container2:active {
  cursor: grabbing;
}
.home-sanfona1-slider {
  width: 100%;
  cursor: grab;
  height: 800px;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.home-sanfona1-slider-slide1 {
  display: flex;
}
.home-sanfona1-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.home-sanfona1-text11 {
  display: inline-block;
}
.home-sanfona1-text13 {
  font-weight: 700;
}
.home-sanfona1-text15 {
  font-weight: 700;
}
.home-sanfona1-slider-slide2 {
  display: flex;
}
.home-sanfona1-text19 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.home-sanfona1-text20 {
  display: inline-block;
}
.home-sanfona1-text22 {
  font-weight: 700;
}
.home-sanfona1-slider-slide3 {
  display: flex;
}
.home-sanfona1-text27 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.home-sanfona1-text28 {
  display: inline-block;
}
.home-sanfona1-text29 {
  font-weight: 700;
}
.home-sanfona1-slider-pagination {
  bottom: 0;
  display: block;
}
.home-sanfona1-slider-button-prev {
  display: none;
}
.home-sanfona1-slider-button-next {
  display: none;
}

@media(max-width: 1200px) {
  .home-sanfona1-slider {
    height: 938px;
  }
}
@media(max-width: 991px) {
  .home-sanfona1-slider {
    height: 941px;
  }
}
@media(max-width: 767px) {
  .home-sanfona1-slider {
    height: 100%;
  }
  .home-sanfona1-text13 {
    font-weight: 700;
  }
  .home-sanfona1-text15 {
    font-weight: 700;
  }
  .home-sanfona1root-class-name {
    height: 100%;
  }
}
@media(max-width: 479px) {
  .home-sanfona1-container1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-sanfona1-container2 {
    height: 766px;
  }
  .home-sanfona1-slider {
    height: 100%;
  }
}
