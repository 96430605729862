.pag1-sanfona3-container10 {
  flex-direction: column;
}
.pag1-sanfona3-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag1-sanfona3-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag1-sanfona3-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona3-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag1-sanfona3-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag1-sanfona3-container11 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag1-sanfona3-container11:active {
  cursor: grabbing;
}
.pag1-sanfona3-container12 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona3-container13 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag1-sanfona3-image1 {
  width: 100%;
  height: 330px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag1-sanfona3-container14 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag1-sanfona3-link1 {
  text-decoration: none;
}
.pag1-sanfona3-link2 {
  display: contents;
}
.pag1-sanfona3-container15 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-principal-azul);
  border-width: 2px;
  border-radius: 100px;
  flex-direction: row;
  text-decoration: none;
}
.pag1-sanfona3-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: 12px;
  margin-top: -2px;
  align-items: center;
  margin-left: -2px;
  border-color: var(--dl-color-principal-azul);
  border-width: 2px;
  border-radius: 100%;
  margin-bottom: -2px;
  padding-bottom: 12px;
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag1-sanfona3-image2 {
  width: 14px;
  height: 14px;
  object-fit: cover;
}
.pag1-sanfona3-text12 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 100%;
  text-align: center;
  font-weight: 700;
}
.pag1-sanfona3-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-principal-verde);
}
.pag1-sanfona3-container18 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag1-sanfona3-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 100px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag1-sanfona3-link3 {
  text-decoration: none;
}
.pag1-sanfona3-image3 {
  width: 420px;
  margin-top: -70px;
  object-fit: cover;
  margin-bottom: -70px;
}
.pag1-sanfona3-text15 {
  display: inline-block;
}
.pag1-sanfona3-text17 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag1-sanfona3-text18 {
  display: inline-block;
}
.pag1-sanfona3-text19 {
  display: inline-block;
}
.pag1-sanfona3-text21 {
  font-weight: 700;
}
.pag1-sanfona3-text23 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 90%;
  height: auto;
  display: inline-block;
}
.pag1-sanfona3-text25 {
  font-family: "BlauerNue-Bold";
}
.pag1-sanfona3-text26 {
  display: inline-block;
}
.pag1-sanfona3-text35 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}

@media(max-width: 991px) {
  .pag1-sanfona3-container16 {
    padding: 16px;
  }
  .pag1-sanfona3-text12 {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width: 767px) {
  .pag1-sanfona3-container12 {
    flex-direction: column;
  }
  .pag1-sanfona3-container17 {
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pag1-sanfona3-image3 {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media(max-width: 479px) {
  .pag1-sanfona3-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag1-sanfona3-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag1-sanfona3-icone-ativo {
    width: 20px;
  }
  .pag1-sanfona3-icone-inativo {
    width: 20px;
  }
  .pag1-sanfona3-container11 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .pag1-sanfona3-container12 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag1-sanfona3-image1 {
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag1-sanfona3-container16 {
    border-style: solid;
  }
  .pag1-sanfona3-text12 {
    width: 180px;
    margin: auto;
  }
  .pag1-sanfona3-container17 {
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
  }
  .pag1-sanfona3-container19 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .pag1-sanfona3-image3 {
    width: 100%;
    margin: 0px;
  }
  .pag1-sanfona3-text17 {
    color: var(--dl-color-principal-azul);
    font-weight: 700;
    text-decoration: underline;
  }
}
