.pag1-sanfona6-container1 {
  flex-direction: column;
}
.pag1-sanfona6-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.pag1-sanfona6-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag1-sanfona6-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag1-sanfona6-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag1-sanfona6-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag1-sanfona6-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag1-sanfona6-container2:active {
  cursor: grabbing;
}
.pag1-sanfona6-slider {
  width: 100%;
  cursor: grab;
  height: auto;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag1-sanfona6-slider-slide1 {
  display: flex;
}
.pag1-sanfona6-text11 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona6-text12 {
  display: inline-block;
}
.pag1-sanfona6-text14 {
  font-weight: 700;
}
.pag1-sanfona6-text19 {
  font-weight: 700;
}
.pag1-sanfona6-text21 {
  font-weight: 700;
}
.pag1-sanfona6-slider-slide2 {
  display: flex;
}
.pag1-sanfona6-text22 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona6-text23 {
  display: inline-block;
}
.pag1-sanfona6-text25 {
  font-weight: 700;
}
.pag1-sanfona6-text31 {
  font-weight: 700;
}
.pag1-sanfona6-text36 {
  font-weight: 700;
}
.pag1-sanfona6-text38 {
  font-size: 20px;
  font-style: normal;
  font-family: "Montserrat";
  letter-spacing: 0em;
}
.pag1-sanfona6-slider-slide3 {
  display: flex;
}
.pag1-sanfona6-text39 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona6-text40 {
  display: inline-block;
}
.pag1-sanfona6-text42 {
  font-weight: 700;
}
.pag1-sanfona6-text44 {
  font-weight: 700;
}
.pag1-sanfona6-text45 {
  font-weight: 700;
}
.pag1-sanfona6-text47 {
  font-weight: 700;
}
.pag1-sanfona6-slider-slide4 {
  display: flex ;
}
.pag1-sanfona6-text48 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona6-text49 {
  display: inline-block;
}
.pag1-sanfona6-text51 {
  font-weight: 700;
}
.pag1-sanfona6-text56 {
  font-weight: 700;
}
.pag1-sanfona6-slider-slide5 {
  display: flex ;
}
.pag1-sanfona6-text58 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona6-text59 {
  display: inline-block;
}
.pag1-sanfona6-text61 {
  font-weight: 700;
}
.pag1-sanfona6-slider-slide6 {
  display: flex ;
}
.pag1-sanfona6-text69 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona6-text70 {
  display: inline-block;
}
.pag1-sanfona6-text72 {
  font-weight: 700;
}
.pag1-sanfona6-text79 {
  font-weight: 700;
}
.pag1-sanfona6-slider-slide7 {
  display: flex ;
}
.pag1-sanfona6-text80 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag1-sanfona6-text81 {
  display: inline-block;
}
.pag1-sanfona6-text83 {
  font-weight: 700;
}
.pag1-sanfona6-text85 {
  font-weight: 700;
}
.pag1-sanfona6-text86 {
  font-weight: 700;
}
.pag1-sanfona6-text89 {
  font-weight: 700;
}
.pag1-sanfona6-slider-pagination {
  display: block;
}
.pag1-sanfona6-slider-button-prev {
  display: none;
}
.pag1-sanfona6-slider-button-next {
  display: none;
}

@media(max-width: 479px) {
  .pag1-sanfona6-topo {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .pag1-sanfona6-botoes {
    margin-left: var(--dl-space-space-unit);
  }
  .pag1-sanfona6-icone-ativo {
    width: 20px;
  }
  .pag1-sanfona6-icone-inativo {
    width: 20px;
  }
  .pag1-sanfona6-slider {
    height: 731px;
  }
  .pag1-sanfona6-text25 {
    font-weight: 700;
  }
  .pag1-sanfona6-text31 {
    font-weight: 700;
  }
  .pag1-sanfona6-text36 {
    font-weight: 700;
  }
}
