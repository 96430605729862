.pag3-sanfona5-container10 {
  flex-direction: column;
}
.pag3-sanfona5-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.pag3-sanfona5-text100 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag3-sanfona5-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag3-sanfona5-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag3-sanfona5-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag3-sanfona5-container11 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag3-sanfona5-container11:active {
  cursor: grabbing;
}
.pag3-sanfona5-container12 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  margin-bottom: -20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.pag3-sanfona5-text103 {
  font-weight: 700;
}
.pag3-sanfona5-text105 {
  font-weight: 700;
}
.pag3-sanfona5-link1 {
  color: rgb(181, 181, 181);
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: underline;
}
.pag3-sanfona5-slider {
  width: 100%;
  cursor: grab;
  height: auto;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag3-sanfona5-slider-slide1 {
  display: flex;
}
.pag3-sanfona5-text106 {
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona5-text107 {
  display: inline-block;
}
.pag3-sanfona5-text109 {
  font-weight: 700;
}
.pag3-sanfona5-text113 {
  font-weight: 700;
}
.pag3-sanfona5-text115 {
  font-weight: 700;
}
.pag3-sanfona5-slider-slide2 {
  display: flex;
}
.pag3-sanfona5-text117 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona5-text118 {
  display: inline-block;
}
.pag3-sanfona5-text122 {
  font-weight: 700;
}
.pag3-sanfona5-text124 {
  font-weight: 700;
}
.pag3-sanfona5-text129 {
  font-weight: 700;
}
.pag3-sanfona5-slider-slide3 {
  display: flex;
}
.pag3-sanfona5-text132 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona5-text133 {
  display: inline-block;
}
.pag3-sanfona5-text135 {
  font-weight: 700;
}
.pag3-sanfona5-text139 {
  font-weight: 700;
}
.pag3-sanfona5-text140 {
  font-weight: 700;
}
.pag3-sanfona5-text143 {
  font-weight: 700;
}
.pag3-sanfona5-slider-slide4 {
  display: flex ;
}
.pag3-sanfona5-text145 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona5-text146 {
  display: inline-block;
}
.pag3-sanfona5-text148 {
  font-weight: 700;
}
.pag3-sanfona5-text150 {
  font-weight: 700;
}
.pag3-sanfona5-slider-slide5 {
  display: flex ;
}
.pag3-sanfona5-text155 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag3-sanfona5-text156 {
  display: inline-block;
}
.pag3-sanfona5-text158 {
  font-weight: 700;
}
.pag3-sanfona5-text160 {
  font-weight: 700;
}
.pag3-sanfona5-text162 {
  font-weight: 700;
}
.pag3-sanfona5-text167 {
  font-weight: 700;
}
.pag3-sanfona5-text169 {
  font-weight: 700;
}
.pag3-sanfona5-slider-pagination {
  display: block;
}
.pag3-sanfona5-slider-button-prev {
  display: none;
}
.pag3-sanfona5-slider-button-next {
  display: none;
}
.pag3-sanfona5-container13 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag3-sanfona5-container14 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona5-text176 {
  font-weight: 700;
}
.pag3-sanfona5-text178 {
  font-weight: 700;
}
.pag3-sanfona5-image1 {
  flex: 1;
  width: 40%;
  align-self: stretch;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag3-sanfona5-container15 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag3-sanfona5-container16 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona5-text182 {
  font-weight: 700;
}
.pag3-sanfona5-text184 {
  font-weight: 700;
}
.pag3-sanfona5-text186 {
  font-weight: 700;
}
.pag3-sanfona5-text187 {
  font-weight: 700;
}
.pag3-sanfona5-text188 {
  font-weight: 700;
}
.pag3-sanfona5-link2 {
  text-decoration: none;
}
.pag3-sanfona5-text191 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag3-sanfona5-image2 {
  flex: 1;
  width: 40%;
  align-self: stretch;
  margin-top: 6px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag3-sanfona5-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag3-sanfona5-container18 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  padding: var(--dl-space-space-twounits);
  overflow: hidden;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag3-sanfona5-container19 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag3-sanfona5-container20 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona5-text194 {
  font-family: "BlauerNue-Light";
}
.pag3-sanfona5-text197 {
  font-weight: 700;
}
.pag3-sanfona5-text199 {
  font-weight: 700;
}
.pag3-sanfona5-image3 {
  flex: 1;
  width: 40%;
  align-self: stretch;
  object-fit: cover;
}
.pag3-sanfona5-text200 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Light";
}
.pag3-sanfona5-text201 {
  display: inline-block;
}
.pag3-sanfona5-text202 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pag3-sanfona5-text203 {
  font-family: "BlauerNue-Thin";
}
.pag3-sanfona5-text205 {
  font-family: "BlauerNue-Thin";
}

@media(max-width: 1200px) {
  .pag3-sanfona5-slider {
    height: 820px;
  }
  .pag3-sanfona5-container18 {
    gap: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .pag3-sanfona5-container11 {
    gap: var(--dl-space-space-twounits);
  }
  .pag3-sanfona5-slider {
    height: 922px;
  }
  .pag3-sanfona5-container13 {
    flex-direction: column;
  }
  .pag3-sanfona5-container14 {
    width: 100%;
  }
  .pag3-sanfona5-image1 {
    width: 100%;
  }
  .pag3-sanfona5-container15 {
    flex-direction: column;
  }
  .pag3-sanfona5-container16 {
    width: 100%;
  }
  .pag3-sanfona5-image2 {
    flex: 1 0 auto;
    width: 100%;
    height: 500px;
  }
  .pag3-sanfona5-container18 {
    padding: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
  }
  .pag3-sanfona5-container19 {
    width: 100%;
    align-items: center;
  }
  .pag3-sanfona5-image3 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .pag3-sanfona5-slider {
    height: 1087px;
  }
  .pag3-sanfona5-container13 {
    flex-direction: column;
  }
  .pag3-sanfona5-container14 {
    width: 100%;
  }
  .pag3-sanfona5-image1 {
    width: 100%;
  }
  .pag3-sanfona5-container15 {
    flex-direction: column;
  }
  .pag3-sanfona5-container16 {
    width: 100%;
  }
  .pag3-sanfona5-image2 {
    width: 100%;
  }
  .pag3-sanfona5-container18 {
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .pag3-sanfona5-container19 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pag3-sanfona5-topo {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .pag3-sanfona5-botoes {
    margin-left: var(--dl-space-space-unit);
  }
  .pag3-sanfona5-icone-ativo {
    width: 20px;
  }
  .pag3-sanfona5-icone-inativo {
    width: 20px;
  }
  .pag3-sanfona5-container11 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag3-sanfona5-container12 {
    gap: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag3-sanfona5-slider {
    height: 771px;
  }
  .pag3-sanfona5-container13 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag3-sanfona5-container14 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag3-sanfona5-image1 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag3-sanfona5-container15 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag3-sanfona5-container16 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag3-sanfona5-image2 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag3-sanfona5-container17 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag3-sanfona5-container18 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .pag3-sanfona5-container19 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    border-radius: var(--dl-radius-radius-radius8);
  }
  .pag3-sanfona5-text201 {
    font-size: 12px;
  }
}
