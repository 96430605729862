.pagina3-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-principal-branco);
}
.pagina3-hero {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.pagina3-image1 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  position: absolute;
  box-shadow: 5px 5px 60px 20px #29b9f2;
  object-fit: cover;
}
.pagina3-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1200px;
  min-width: auto;
  align-items: center;
  flex-direction: column;
}
.pagina3-container3 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  align-items: flex-start;
}
.pagina3-text10 {
  fill: var(--dl-color-principal-verde);
  color: var(--dl-color-principal-verde);
  font-size: 48px;
  font-style: italic;
  font-weight: 400;
}
.pagina3-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 735px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pagina3-text11 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 100%;
}
.pagina3-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.pagina3-boto {
  gap: 10px;
  display: flex;
  padding: 20px;
  z-index: 2;
  align-items: center;
  margin-right: var(--dl-space-space-fourunits);
  border-radius: 30px;
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina3-text15 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600px;
  font-stretch: normal;
}
.pagina3-indicaes {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
}
.pagina3-vector1 {
  width: 52px;
  height: 55px;
}
.pagina3-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.pagina3-vector3 {
  width: 52px;
  height: 55px;
}
.pagina3-image2 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pagina3-container6 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.pagina3-vector4 {
  width: 36px;
  height: 72px;
}
.pagina3-text17 {
  font-weight: 700;
}
.pagina3-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.pagina3-text21 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina3-text22 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina3-text23 {
  font-family: "BlauerNue-Light";
}
.pagina3-text25 {
  display: inline-block;
}
.pagina3-text26 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Light";
}
.pagina3-text27 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina3-text28 {
  font-family: "BlauerNue-Thin";
}
.pagina3-text30 {
  font-family: "BlauerNue-Thin";
}
.pagina3-sugestao-conteudo {
  width: 100%;
  height: auto;
  display: flex;
  padding: 0px;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina3-interno {
  gap: -97px;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.pagina3-texto-ei {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pagina3-text31 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  text-align: left;
}
.pagina3-text32 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 440px;
  align-self: stretch;
  text-align: left;
  letter-spacing: 0em;
}
.pagina3-button {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  margin-top: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-secundario-rosa);
}
.pagina3-container8 {
  flex: 0 0 auto;
  right: -69px;
  width: 579px;
  bottom: 0px;
  height: 410px;
  display: flex;
  position: absolute;
  align-self: flex-end;
  align-items: flex-end;
  pointer-events: none;
  justify-content: center;
}
.pagina3-image3 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.pagina3-container9 {
  flex: 0 0 auto;
  right: 0px;
  width: 579px;
  bottom: 0px;
  height: 410px;
  display: none;
  position: absolute;
  align-self: flex-end;
  align-items: flex-end;
  pointer-events: none;
  justify-content: flex-start;
}
.pagina3-image4 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .pagina3-container3 {
    width: auto;
  }
  .pagina3-text11 {
    color: var(--dl-color-principal-branco);
  }
  .pagina3-texto-ei {
    width: 422px;
    margin-right: 0px;
  }
  .pagina3-container8 {
    right: -13px;
    width: 540px;
  }
  .pagina3-container9 {
    width: 540px;
  }
}
@media(max-width: 991px) {
  .pagina3-image1 {
    height: 506px;
  }
  .pagina3-container3 {
    width: 100%;
  }
  .pagina3-container4 {
    width: 100%;
  }
  .pagina3-sugestao-conteudo {
    height: auto;
    padding: 0px;
    flex-direction: column;
  }
  .pagina3-interno {
    padding: 0px;
    flex-direction: row;
  }
  .pagina3-texto-ei {
    width: 420px;
    margin: 0px;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .pagina3-container8 {
    top: 0px;
    right: -256px;
    width: 100%;
    position: absolute;
  }
  .pagina3-image3 {
    width: 100%;
  }
  .pagina3-container9 {
    width: 100%;
    position: static;
  }
  .pagina3-image4 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .pagina3-image1 {
    height: 695px;
  }
  .pagina3-container3 {
    width: 100%;
    flex-direction: column;
  }
  .pagina3-container4 {
    width: 100%;
  }
  .pagina3-interno {
    flex-direction: column;
  }
  .pagina3-texto-ei {
    width: 420px;
  }
  .pagina3-text31 {
    width: 100%;
  }
  .pagina3-container8 {
    position: static;
  }
}
@media(max-width: 479px) {
  .pagina3-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .pagina3-image1 {
    height: 240px;
  }
  .pagina3-container2 {
    gap: var(--dl-space-space-unit);
  }
  .pagina3-container3 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .pagina3-text10 {
    font-size: 16px;
  }
  .pagina3-container4 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    width: 100%;
    height: auto;
  }
  .pagina3-text11 {
    color: var(--dl-color-principal-branco);
    width: auto;
  }
  .pagina3-container5 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .pagina3-boto {
    padding-top: 10px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 10px;
  }
  .pagina3-text15 {
    color: var(--dl-color-secundario-azul-forte);
  }
  .pagina3-indicaes {
    gap: var(--dl-space-space-halfunit);
  }
  .pagina3-vector1 {
    width: 24px;
    height: 24px;
  }
  .pagina3-vector2 {
    width: 24px;
    height: 24px;
  }
  .pagina3-vector3 {
    width: 24px;
    height: 24px;
  }
  .pagina3-image2 {
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pagina3-container6 {
    gap: var(--dl-space-space-unit);
  }
  .pagina3-vector4 {
    width: 10px;
    height: 19px;
  }
  .pagina3-container7 {
    padding: 0px;
  }
  .pagina3-text25 {
    font-size: 12px;
  }
  .pagina3-sugestao-conteudo {
    height: auto;
    padding: 0px;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pagina3-interno {
    gap: 0px;
    height: auto;
    overflow: hidden;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .pagina3-texto-ei {
    gap: var(--dl-space-space-unit);
    width: 100%;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 120px;
  }
  .pagina3-text31 {
    width: 100%;
  }
  .pagina3-text32 {
    color: var(--dl-color-secundario-azul-forte);
    width: 100%;
    letter-spacing: 0em;
  }
  .pagina3-button {
    margin-top: 0px;
    padding-top: 12px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 12px;
    text-decoration: none;
  }
  .pagina3-container8 {
    width: 100%;
    height: auto;
    z-index: 4;
    position: static;
  }
  .pagina3-image3 {
    transform: scale(2);
    transform-origin: bottom;
  }
  .pagina3-container9 {
    width: 100%;
    height: auto;
    display: none;
    z-index: 4;
    position: static;
  }
  .pagina3-image4 {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
