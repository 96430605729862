.pag2-sanfona1-container1 {
  flex-direction: column;
}
.pag2-sanfona1-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag2-sanfona1-text100 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag2-sanfona1-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag2-sanfona1-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag2-sanfona1-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag2-sanfona1-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag2-sanfona1-container2:active {
  cursor: grabbing;
}
.pag2-sanfona1-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag2-sanfona1-image1 {
  flex: 1;
  width: 50%;
  height: auto;
  align-self: stretch;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag2-sanfona1-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag2-sanfona1-link1 {
  text-decoration: none;
}
.pag2-sanfona1-text102 {
  font-weight: 700;
}
.pag2-sanfona1-text104 {
  font-weight: 700;
}
.pag2-sanfona1-text106 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag2-sanfona1-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag2-sanfona1-container6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  background-color: #C0D99C;
}
.pag2-sanfona1-container7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 530px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag2-sanfona1-text113 {
  font-weight: 700;
}
.pag2-sanfona1-text115 {
  font-weight: 700;
}
.pag2-sanfona1-text116 {
  font-weight: 700;
}
.pag2-sanfona1-text118 {
  font-weight: 700;
}
.pag2-sanfona1-text119 {
  font-weight: 700;
}
.pag2-sanfona1-text120 {
  font-weight: 700;
}
.pag2-sanfona1-text122 {
  font-weight: 700;
}
.pag2-sanfona1-text124 {
  font-weight: 700;
}
.pag2-sanfona1-text126 {
  font-weight: 700;
}
.pag2-sanfona1-text127 {
  font-weight: 700;
}
.pag2-sanfona1-text129 {
  font-weight: 700;
}
.pag2-sanfona1-text131 {
  font-weight: 700;
}
.pag2-sanfona1-text133 {
  font-weight: 700;
}
.pag2-sanfona1-text134 {
  font-weight: 700;
}
.pag2-sanfona1-container8 {
  gap: 4px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.pag2-sanfona1-text136 {
  white-space: nowrap;
}
.pag2-sanfona1-link2 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  white-space: nowrap;
  text-decoration: underline;
}
.pag2-sanfona1-text139 {
  white-space: nowrap;
}
.pag2-sanfona1-link3 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  white-space: nowrap;
  text-decoration: underline;
}
.pag2-sanfona1-image2 {
  width: 40%;
  transform: rotate(-7deg);
  object-fit: cover;
}
.pag2-sanfona1-container9 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag2-sanfona1-text140 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 100%;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
}
.pag2-sanfona1-slider {
  width: 100%;
  cursor: grab;
  height: auto;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag2-sanfona1-slider-slide1 {
  display: flex;
}
.pag2-sanfona1-text141 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona1-text144 {
  display: inline-block;
}
.pag2-sanfona1-text146 {
  font-weight: 700;
}
.pag2-sanfona1-slider-slide2 {
  display: flex;
}
.pag2-sanfona1-text149 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona1-text150 {
  display: inline-block;
}
.pag2-sanfona1-text152 {
  font-weight: 700;
}
.pag2-sanfona1-text155 {
  font-weight: 700;
}
.pag2-sanfona1-text156 {
  font-weight: 700;
}
.pag2-sanfona1-slider-slide3 {
  display: flex;
}
.pag2-sanfona1-text159 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona1-text160 {
  display: inline-block;
}
.pag2-sanfona1-text162 {
  font-weight: 700;
}
.pag2-sanfona1-text164 {
  font-weight: 700;
}
.pag2-sanfona1-text168 {
  font-weight: 700;
}
.pag2-sanfona1-text170 {
  font-weight: 700;
}
.pag2-sanfona1-text172 {
  font-weight: 700;
}
.pag2-sanfona1-slider-slide4 {
  display: flex ;
}
.pag2-sanfona1-text174 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona1-text175 {
  display: inline-block;
}
.pag2-sanfona1-text179 {
  font-weight: 700;
}
.pag2-sanfona1-text184 {
  font-weight: 700;
}
.pag2-sanfona1-text186 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag2-sanfona1-slider-slide5 {
  display: flex ;
}
.pag2-sanfona1-text189 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag2-sanfona1-text190 {
  display: inline-block;
}
.pag2-sanfona1-text200 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag2-sanfona1-slider-pagination {
  display: block;
}
.pag2-sanfona1-slider-button-prev {
  display: none;
}
.pag2-sanfona1-slider-button-next {
  display: none;
}
.pag2-sanfona1-text202 {
  width: 100%;
  display: inline-block;
}

@media(max-width: 1200px) {
  .pag2-sanfona1-container6 {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    justify-content: flex-start;
  }
  .pag2-sanfona1-slider {
    height: 820px;
  }
}
@media(max-width: 991px) {
  .pag2-sanfona1-container3 {
    flex-direction: column;
  }
  .pag2-sanfona1-image1 {
    width: 100%;
  }
  .pag2-sanfona1-container4 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .pag2-sanfona1-container3 {
    flex-direction: column;
  }
  .pag2-sanfona1-image1 {
    width: 100%;
  }
  .pag2-sanfona1-container4 {
    width: 100%;
  }
  .pag2-sanfona1-container6 {
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pag2-sanfona1-container7 {
    width: 100%;
  }
  .pag2-sanfona1-container8 {
    width: 100%;
    flex-wrap: wrap;
  }
  .pag2-sanfona1-image2 {
    width: 100%;
  }
  .pag2-sanfona1-slider {
    width: 100%;
    height: 1217px;
  }
}
@media(max-width: 479px) {
  .pag2-sanfona1-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag2-sanfona1-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag2-sanfona1-icone-ativo {
    width: 20px;
  }
  .pag2-sanfona1-icone-inativo {
    width: 20px;
  }
  .pag2-sanfona1-container2 {
    gap: var(--dl-space-space-twounits);
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag2-sanfona1-container3 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag2-sanfona1-image1 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag2-sanfona1-container4 {
    width: 100%;
  }
  .pag2-sanfona1-container5 {
    height: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag2-sanfona1-container6 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
    justify-content: flex-start;
  }
  .pag2-sanfona1-container7 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag2-sanfona1-image2 {
    width: 70%;
    margin: 0px;
  }
  .pag2-sanfona1-text140 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pag2-sanfona1-slider {
    height: 764px;
  }
}
