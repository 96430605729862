.seo-final-seo-final {
  flex: 0 0 auto;
  width: 100%;
  height: 800px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("/Secao final/secao%20final%20fundo-800h.png");
}
.seo-final-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  justify-content: space-between;
}
.seo-final-container2 {
  gap: var(--dl-space-space-fiveunits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.seo-final-container3 {
  gap: 80px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.seo-final-image1 {
  width: 250px;
  object-fit: cover;
}
.seo-final-image2 {
  width: 440px;
  object-fit: cover;
}
.seo-final-image3 {
  width: 440px;
  display: none;
  object-fit: cover;
}
.seo-final-button {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-principal-verde);
}
.seo-final-robo {
  flex: 0 0 auto;
  width: fit-content;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
}
.seo-final-image4 {
  left: 0px;
  right: 0px;
  width: 528px;
  bottom: -60px;
  height: 106px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  pointer-events: none;
}
.seo-final-image5 {
  height: 100%;
  z-index: 100;
  object-fit: cover;
  transition: 0.3s;
  transform-origin: bottom center;
}
.seo-final-image5:hover {
  transform: rotate(-8deg);
}
.seo-final-link {
  display: contents;
}
.seo-final-image6 {
  right: 0px;
  width: 20px;
  bottom: var(--dl-space-space-threeunits);
  cursor: pointer;
  z-index: 999;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.seo-final-image7 {
  top: 383px;
  right: 4px;
  width: 190px;
  position: absolute;
  object-fit: cover;
}






@media(max-width: 1200px) {
  .seo-final-image5 {
    display: none;
  }
  .seo-final-image6 {
    display: none;
  }
  .seo-final-image7 {
    display: none;
  }
}
@media(max-width: 991px) {
  .seo-final-container1 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .seo-final-image1 {
    width: 180px;
  }
  .seo-final-image2 {
    width: 340px;
    margin-right: 0px;
  }
}
@media(max-width: 767px) {
  .seo-final-container3 {
    gap: 20px;
    flex-direction: column;
  }
  .seo-final-image2 {
    width: 100%;
  }
  .seo-final-image7 {
    display: none;
  }
}
@media(max-width: 479px) {
  .seo-final-seo-final {
    height: auto;
    background-position: bottom;
  }
  .seo-final-container1 {
    gap: var(--dl-space-space-threeunits);
    margin: 0px;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column-reverse;
    padding-bottom: var(--dl-space-space-fourunits);
    justify-content: flex-start;
  }
  .seo-final-container2 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    z-index: 2;
    align-items: center;
  }
  .seo-final-container3 {
    gap: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .seo-final-image1 {
    width: 120px;
  }
  .seo-final-image2 {
    width: 168px;
    display: none;
  }
  .seo-final-image3 {
    width: 168px;
    display: flex;
  }
  .seo-final-button {
    color: var(--dl-color-principal-azul);
    padding-top: 12px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 12px;
    text-decoration: none;
    background-color: var(--dl-color-principal-verde);
  }
  .seo-final-robo {
    width: 100%;
    z-index: 100;
  }
  .seo-final-image4 {
    left: 13%;
    right: auto;
    scale: 1.5;
    width: 100%;
    bottom: -9%;
    height: auto;
    margin: auto;
    z-index: 100;
  }
  .seo-final-image5 {
    flex: 1;
    width: 90%;
    display: flex;
  }
  .seo-final-image6 {
    right: 20px;
    bottom: 20px;
    display: none;
  }
  .seo-final-image7 {
    display: none;
  }
}
