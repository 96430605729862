.navbar-navbar {
  width: 100%;
  height: fit-content;
  display: flex;
  z-index: 10;
  position: relative;
  align-self: stretch;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  flex-direction: column;
  background-color: var(--dl-color-principal-branco);
}
.navbar-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.navbar-container2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.navbar-vector1 {
  width: 38px;
  height: 35px;
}
.navbar-navlink {
  display: contents;
}
.navbar-vector2 {
  width: 240px;
  text-decoration: none;
}
.navbar-container3 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.navbar-image {
  width: 200px;
  display: none;
  object-fit: cover;
}
.navbar-vector3 {
  width: 134px;
}







@media(max-width: 479px) {
  .navbar-navbar {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .navbar-container1 {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .navbar-container2 {
    gap: var(--dl-space-space-unit);
  }
  .navbar-vector1 {
    width: 24px;
    height: 100%;
  }
  .navbar-vector2 {
    width: 180px;
    display: none;
  }
  .navbar-container3 {
    gap: var(--dl-space-space-unit);
  }
  .navbar-image {
    width: 85px;
    display: flex;
    justify-content: flex-start;
  }
  .navbar-vector3 {
    width: 62px;
  }
}
