.pag5-sanfona1-container1 {
  flex-direction: column;
}
.pag5-sanfona1-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag5-sanfona1-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag5-sanfona1-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag5-sanfona1-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag5-sanfona1-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag5-sanfona1-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag5-sanfona1-container2:active {
  cursor: grabbing;
}
.pag5-sanfona1-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag5-sanfona1-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag5-sanfona1-text14 {
  font-weight: 700;
}
.pag5-sanfona1-text19 {
  font-weight: 700;
}
.pag5-sanfona1-text21 {
  font-weight: 700;
}
.pag5-sanfona1-text26 {
  font-weight: 700;
}
.pag5-sanfona1-image1 {
  width: 40%;
  align-self: stretch;
  object-fit: cover;
}
.pag5-sanfona1-container5 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag5-sanfona1-slider {
  width: 100%;
  cursor: grab;
  height: 583px;
  display: inline-block;
  position: relative;
  padding-bottom: var(--dl-space-space-unit);
}
.pag5-sanfona1-slider-slide1 {
  display: flex;
}
.pag5-sanfona1-text31 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona1-text32 {
  display: inline-block;
}
.pag5-sanfona1-text33 {
  font-weight: 700;
}
.pag5-sanfona1-text35 {
  font-weight: 700;
}
.pag5-sanfona1-text36 {
  font-weight: 700;
}
.pag5-sanfona1-text38 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag5-sanfona1-slider-slide2 {
  display: flex;
}
.pag5-sanfona1-text39 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona1-text40 {
  display: inline-block;
}
.pag5-sanfona1-text42 {
  font-weight: 700;
}
.pag5-sanfona1-text47 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag5-sanfona1-slider-slide3 {
  display: flex;
}
.pag5-sanfona1-text48 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona1-text49 {
  display: inline-block;
}
.pag5-sanfona1-text51 {
  font-weight: 700;
}
.pag5-sanfona1-text54 {
  font-weight: 700;
}
.pag5-sanfona1-text59 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag5-sanfona1-slider-slide4 {
  display: flex ;
}
.pag5-sanfona1-text60 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona1-text61 {
  display: inline-block;
}
.pag5-sanfona1-text63 {
  font-weight: 700;
}
.pag5-sanfona1-text68 {
  font-weight: 700;
}
.pag5-sanfona1-text69 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag5-sanfona1-slider-slide5 {
  display: flex ;
}
.pag5-sanfona1-container6 {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  flex-direction: column;
}
.pag5-sanfona1-interno {
  width: 100%;
  height: 640px;
  display: flex;
  z-index: 3;
  overflow: hidden;
  max-width: 1142px;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  align-items: center;
  border-radius: 30px;
  flex-direction: row;
  background-color: var(--dl-color-principal-branco);
}
.pag5-sanfona1-image-desktop {
  width: 500px;
  height: 100%;
  object-fit: cover;
}
.pag5-sanfona1-image-mobile {
  width: 500px;
  height: 100%;
  object-fit: cover;
}
.pag5-sanfona1-frame1000003600 {
  gap: 32px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.pag5-sanfona1-image2 {
  width: 40px;
  object-fit: cover;
}
.pag5-sanfona1-text70 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 100%;
  height: auto;
}
.pag5-sanfona1-text71 {
  color: rgb(23, 23, 23);
  height: auto;
  align-self: stretch;
  text-align: left;
  text-decoration: none;
}
.pag5-sanfona1-text72 {
  color: rgb(23, 23, 23);
  height: auto;
  align-self: stretch;
  text-align: left;
  text-decoration: none;
}
.pag5-sanfona1-indicaes {
  gap: 19px;
  display: none;
  position: relative;
  margin-top: auto;
  align-items: center;
}
.pag5-sanfona1djctql1 {
  width: 52px;
  height: 55px;
  border-radius: 6px;
}
.pag5-sanfona1-vector1 {
  width: 52px;
  height: 55px;
}
.pag5-sanfona1-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.pag5-sanfona1-vector3 {
  width: 52px;
  height: 55px;
}
.pag5-sanfona1-slider-slide6 {
  display: flex ;
}
.pag5-sanfona1-text73 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona1-text74 {
  display: inline-block;
}
.pag5-sanfona1-text76 {
  font-weight: 700;
}
.pag5-sanfona1-text81 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag5-sanfona1-slider-pagination {
  display: block;
}
.pag5-sanfona1-slider-button-prev {
  display: none;
}
.pag5-sanfona1-slider-button-next {
  display: none;
}
.pag5-sanfona1-text82 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pag5-sanfona1-text83 {
  font-family: "BlauerNue-Light";
}
.pag5-sanfona1-text85 {
  display: inline-block;
}
.pag5-sanfona1-text87 {
  font-weight: 700;
}
.pag5-sanfona1-text89 {
  font-weight: 700;
}
.pag5-sanfona1-text90 {
  font-weight: 700;
}
.pag5-sanfona1-text92 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag5-sanfona1-text93 {
  display: inline-block;
}
.pag5-sanfona1-text95 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag5-sanfona1-text97 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.pag5-sanfona1-text98 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pag5-sanfona1-text99 {
  font-family: "BlauerNue-Light";
}
.pag5-sanfona1-text101 {
  font-family: "BlauerNue-Light";
}

@media(max-width: 1200px) {
  .pag5-sanfona1-slider {
    height: 679px;
  }
  .pag5-sanfona1-interno {
    height: 100%;
  }
  .pag5-sanfona1-image-desktop {
    height: 100%;
  }
  .pag5-sanfona1-image-mobile {
    height: 100%;
  }
}
@media(max-width: 991px) {
  .pag5-sanfona1-container3 {
    flex-direction: row;
  }
  .pag5-sanfona1-container4 {
    width: 100%;
  }
  .pag5-sanfona1-image1 {
    flex: 1;
    width: 40%;
    height: 480px;
  }
  .pag5-sanfona1-container5 {
    flex-direction: column;
  }
  .pag5-sanfona1-slider {
    height: 606px;
  }
  .pag5-sanfona1-image-desktop {
    width: 40%;
  }
}
@media(max-width: 767px) {
  .pag5-sanfona1-container3 {
    flex-direction: column;
  }
  .pag5-sanfona1-container4 {
    width: 100%;
  }
  .pag5-sanfona1-image1 {
    flex: 0 0 auto;
    width: 100%;
    height: 420px;
  }
  .pag5-sanfona1-container5 {
    flex-direction: column;
  }
  .pag5-sanfona1-slider {
    height: 878px;
  }
  .pag5-sanfona1-interno {
    flex-direction: column;
  }
  .pag5-sanfona1-image-mobile {
    width: 100%;
    height: 400px;
  }
  .pag5-sanfona1-frame1000003600 {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .pag5-sanfona1-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag5-sanfona1-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag5-sanfona1-icone-ativo {
    width: 20px;
  }
  .pag5-sanfona1-icone-inativo {
    width: 20px;
  }
  .pag5-sanfona1-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag5-sanfona1-container3 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag5-sanfona1-container4 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag5-sanfona1-image1 {
    width: 100%;
    height: 260px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag5-sanfona1-container5 {
    gap: 0px;
    flex-direction: column;
  }
  .pag5-sanfona1-slider {
    height: 597px;
  }
  .pag5-sanfona1-container6 {
    height: 100%;
  }
  .pag5-sanfona1-interno {
    height: 100%;
    flex-direction: column;
  }
  .pag5-sanfona1-image-desktop {
    flex: 0 0 auto;
    width: 100%;
    height: 30%;
  }
  .pag5-sanfona1-image-mobile {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
  .pag5-sanfona1-frame1000003600 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    height: 100%;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .pag5-sanfona1-image2 {
    width: 32px;
  }
  .pag5-sanfona1-slider-slide6 {
    position: relative;
  }
  .pag5-sanfona1-text82 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
