.hero-pages-hero {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.hero-pages-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  position: absolute;
  box-shadow: 5px 5px 60px 20px #29b9f2;
  object-fit: cover;
}
.hero-pages-container1 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1200px;
  min-width: auto;
  align-items: center;
  flex-direction: column;
}
.hero-pages-container2 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 70%;
  display: flex;
  align-items: flex-start;
}
.hero-pages-text10 {
  fill: var(--dl-color-principal-verde);
  color: var(--dl-color-principal-verde);
  font-size: 48px;
  font-style: italic;
  font-family: "Montserrat";
  font-weight: 400;
}
.hero-pages-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 700px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.hero-pages-text11 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 100%;
}
.hero-pages-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.hero-pages-boto {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 20px;
  z-index: 2;
  align-items: center;
  margin-right: var(--dl-space-space-fourunits);
  border-radius: 30px;
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.hero-pages-text14 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600px;
  font-stretch: normal;
}
.hero-pages-indicaes {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
}
.hero-pages-vector1 {
  width: 52px;
  height: 55px;
}
.hero-pages-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.hero-pages-vector3 {
  width: 52px;
  height: 55px;
}
.hero-pages-container5 {
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
  border-radius: var(--dl-radius-radius-radius16);
}
.hero-pages-iframe1 {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
}
.hero-pages-container6 {
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
  border-radius: var(--dl-radius-radius-radius16);
}
.hero-pages-iframe2 {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
}
.hero-pages-container7 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.hero-pages-vector4 {
  width: 36px;
  height: 72px;
}
.hero-pages-text16 {
  font-weight: 700;
}
@media(max-width: 1200px) {
  .hero-pages-text11 {
    color: var(--dl-color-principal-branco);
  }
}
@media(max-width: 991px) {
  .hero-pages-container2 {
    width: 100%;
  }
  .hero-pages-container3 {
    flex: 1;
    width: 100%;
  }
  .hero-pages-text11 {
    width: 660px;
  }
}
@media(max-width: 767px) {
  .hero-pages-image {
    height: 562px;
  }
  .hero-pages-container2 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: column;
  }
  .hero-pages-container3 {
    width: 100%;
  }
  .hero-pages-text11 {
    width: auto;
  }
}
@media(max-width: 479px) {
  .hero-pages-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .hero-pages-image {
    height: 240px;
  }
  .hero-pages-container1 {
    gap: var(--dl-space-space-unit);
  }
  .hero-pages-container2 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .hero-pages-text10 {
    font-size: 16px;
  }
  .hero-pages-container3 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    width: 100%;
  }
  .hero-pages-text11 {
    color: var(--dl-color-principal-branco);
    width: 300px;
  }
  .hero-pages-text12 {
    font-family: BlauerNue-Regular;
  }
  .hero-pages-container4 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .hero-pages-boto {
    padding-top: 10px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 10px;
  }
  .hero-pages-text14 {
    color: var(--dl-color-secundario-azul-forte);
  }
  .hero-pages-indicaes {
    gap: var(--dl-space-space-halfunit);
  }
  .hero-pages-vector1 {
    width: 24px;
    height: 24px;
  }
  .hero-pages-vector2 {
    width: 24px;
    height: 24px;
  }
  .hero-pages-vector3 {
    width: 24px;
    height: 24px;
  }
  .hero-pages-container7 {
    gap: var(--dl-space-space-unit);
  }
  .hero-pages-vector4 {
    width: 10px;
    height: 19px;
  }
}
