.pagina4-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-principal-branco);
}
.pagina4-hero {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.pagina4-image1 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  position: absolute;
  box-shadow: 5px 5px 60px 20px #29b9f2;
  object-fit: cover;
}
.pagina4-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1200px;
  min-width: auto;
  align-items: center;
  flex-direction: column;
}
.pagina4-container3 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.pagina4-text10 {
  fill: var(--dl-color-principal-verde);
  color: var(--dl-color-principal-verde);
  font-size: 48px;
  font-style: italic;
  font-weight: 400;
}
.pagina4-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pagina4-text11 {
  fill: var(--dl-color-principal-branco);
  color: var(--dl-color-principal-branco);
  width: 100%;
}
.pagina4-text12 {
  font-family: "BlauerNue-Bold";
}
.pagina4-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.pagina4-boto {
  gap: 10px;
  display: flex;
  padding: 20px;
  z-index: 2;
  align-items: center;
  margin-right: var(--dl-space-space-fourunits);
  border-radius: 30px;
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina4-text15 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600px;
  font-stretch: normal;
}
.pagina4-indicaes {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
}
.pagina4-vector1 {
  width: 52px;
  height: 55px;
}
.pagina4-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.pagina4-vector3 {
  width: 52px;
  height: 55px;
}
.pagina4-image2 {
  width: 100%;
  object-fit: cover;
}
.pagina4-container6 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.pagina4-vector4 {
  width: 36px;
  height: 72px;
}
.pagina4-text17 {
  font-weight: 700;
}
.pagina4-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.pagina4-text21 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina4-text23 {
  font-family: "BlauerNue-Light";
}
.pagina4-text25 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina4-text27 {
  font-family: "BlauerNue-Light";
}
.pagina4-text29 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina4-text31 {
  font-family: "BlauerNue-Light";
}
.pagina4-text33 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina4-text34 {
  font-family: "BlauerNue-Light";
}
.pagina4-text36 {
  display: inline-block;
}
.pagina4-text37 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 100%;
  display: inline-block;
}
.pagina4-text39 {
  font-weight: 700;
}
.pagina4-text41 {
  font-weight: 700;
}
.pagina4-text47 {
  font-weight: 700;
  text-decoration: underline;
}
.pagina4-text49 {
  font-weight: 700;
  text-decoration: underline;
}
.pagina4-text51 {
  font-weight: 700;
  text-decoration: underline;
}
.pagina4-text53 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 100%;
  display: inline-block;
}
.pagina4-text55 {
  font-weight: 700;
}
.pagina4-text60 {
  font-weight: 700;
  text-decoration: underline;
}
.pagina4-text62 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pagina4-text64 {
  font-family: "BlauerNue-Light";
}
.pagina4-sugestao-conteudo1 {
  width: 100%;
  height: 360px;
  display: none;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina4-interno1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-start;
}
.pagina4-texto-ei1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.pagina4-text65 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  text-align: left;
}
.pagina4-text66 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 440px;
  align-self: stretch;
  text-align: left;
  letter-spacing: 0em;
}
.pagina4-button1 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  margin-top: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-secundario-rosa);
}
.pagina4-container8 {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  pointer-events: none;
  justify-content: flex-start;
}
.pagina4-image3 {
  width: auto;
  height: 110%;
  object-fit: cover;
}
.pagina4-sugestao-conteudo2 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina4-interno2 {
  gap: -97px;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.pagina4-texto-ei2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pagina4-text67 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  text-align: left;
}
.pagina4-text68 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 440px;
  align-self: stretch;
  text-align: left;
  letter-spacing: 0em;
}
.pagina4-button2 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  margin-top: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-secundario-rosa);
}
.pagina4-container9 {
  top: -18px;
  flex: 0 0 auto;
  right: -96px;
  width: 579px;
  bottom: 0px;
  height: 410px;
  display: flex;
  position: absolute;
  align-self: flex-end;
  align-items: flex-end;
  pointer-events: none;
  justify-content: center;
}
.pagina4-image4 {
  width: auto;
  height: 100%;
  object-fit: cover;
}
@media(max-width: 1200px) {
  .pagina4-container3 {
    width: auto;
  }
  .pagina4-text11 {
    color: var(--dl-color-principal-branco);
  }
  .pagina4-sugestao-conteudo1 {
    height: auto;
  }
  .pagina4-interno1 {
    height: auto;
    padding: 0px;
    flex-direction: row;
  }
  .pagina4-texto-ei1 {
    width: 476px;
    margin-right: 0px;
  }
  .pagina4-container8 {
    width: 50%;
    height: auto;
  }
  .pagina4-image3 {
    width: 100%;
    height: auto;
  }
  .pagina4-texto-ei2 {
    width: 422px;
    margin-right: 0px;
  }
  .pagina4-container9 {
    top: -52px;
    right: -13px;
    width: 540px;
  }
}
@media(max-width: 991px) {
  .pagina4-image1 {
    height: 506px;
  }
  .pagina4-container3 {
    width: 100%;
  }
  .pagina4-container4 {
    width: 100%;
  }
  .pagina4-text37 {
    width: 100%;
  }
  .pagina4-text53 {
    width: 100%;
  }
  .pagina4-sugestao-conteudo1 {
    padding: 0px;
  }
  .pagina4-interno1 {
    flex-direction: column;
  }
  .pagina4-container8 {
    width: 100%;
  }
  .pagina4-image3 {
    width: 100%;
    height: auto;
    transform: scale(1.1);
    margin-right: 0px;
    transform-origin: bottom;
  }
  .pagina4-sugestao-conteudo2 {
    height: auto;
    padding: 0px;
    flex-direction: column;
  }
  .pagina4-interno2 {
    padding: 0px;
    flex-direction: row;
  }
  .pagina4-texto-ei2 {
    width: 420px;
    margin: 0px;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .pagina4-container9 {
    top: -2px;
    right: -151px;
    width: 689px;
    overflow: visible;
    position: absolute;
  }
  .pagina4-image4 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .pagina4-image1 {
    height: 695px;
  }
  .pagina4-container3 {
    width: 100%;
    flex-direction: column;
  }
  .pagina4-container4 {
    width: 100%;
  }
  .pagina4-interno2 {
    flex-direction: column;
  }
  .pagina4-texto-ei2 {
    width: 420px;
  }
  .pagina4-text67 {
    width: 100%;
  }
  .pagina4-container9 {
    width: 100%;
    position: static;
  }
}
@media(max-width: 479px) {
  .pagina4-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .pagina4-image1 {
    height: 240px;
  }
  .pagina4-container2 {
    gap: var(--dl-space-space-unit);
  }
  .pagina4-container3 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .pagina4-text10 {
    font-size: 16px;
  }
  .pagina4-container4 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    width: 100%;
    height: auto;
  }
  .pagina4-text11 {
    color: var(--dl-color-principal-branco);
    width: auto;
  }
  .pagina4-container5 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .pagina4-boto {
    padding-top: 10px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 10px;
  }
  .pagina4-text15 {
    color: var(--dl-color-secundario-azul-forte);
  }
  .pagina4-indicaes {
    gap: var(--dl-space-space-halfunit);
  }
  .pagina4-vector1 {
    width: 24px;
    height: 24px;
  }
  .pagina4-vector2 {
    width: 24px;
    height: 24px;
  }
  .pagina4-vector3 {
    width: 24px;
    height: 24px;
  }
  .pagina4-container6 {
    gap: var(--dl-space-space-unit);
  }
  .pagina4-vector4 {
    width: 10px;
    height: 19px;
  }
  .pagina4-container7 {
    padding: 0px;
  }
  .pagina4-text37 {
    width: 100%;
  }
  .pagina4-text53 {
    width: 100%;
  }
  .pagina4-sugestao-conteudo1 {
    height: auto;
    padding: 0px;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pagina4-interno1 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: auto;
    padding: 0px;
    overflow: hidden;
    flex-direction: column;
  }
  .pagina4-texto-ei1 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
  }
  .pagina4-text65 {
    width: 100%;
  }
  .pagina4-text66 {
    color: var(--dl-color-secundario-azul-forte);
    width: 100%;
    letter-spacing: 0em;
  }
  .pagina4-button1 {
    margin-top: 0px;
    padding-top: 12px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 12px;
    text-decoration: none;
  }
  .pagina4-container8 {
    width: 100%;
    height: auto;
    z-index: 4;
    position: static;
  }
  .pagina4-sugestao-conteudo2 {
    height: auto;
    padding: 0px;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pagina4-interno2 {
    gap: 0px;
    height: auto;
    overflow: hidden;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .pagina4-texto-ei2 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 100px;
  }
  .pagina4-text67 {
    width: 100%;
  }
  .pagina4-text68 {
    color: var(--dl-color-secundario-azul-forte);
    width: 100%;
    letter-spacing: 0em;
  }
  .pagina4-button2 {
    margin-top: 0px;
    padding-top: 12px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 12px;
    text-decoration: none;
  }
  .pagina4-container9 {
    width: 100%;
    height: auto;
    z-index: 4;
    position: static;
  }
  .pagina4-image4 {
    width: 100%;
    height: auto;
    transform: scale(1.3);
    transform-origin: bottom;
  }
}
