.pagina1-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-principal-branco);
}
.pagina1-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.pagina1-text10 {
  width: 585px;
  display: inline-block;
}
.pagina1-text14 {
  font-weight: 700;
}
.pagina1-text18 {
  display: inline-block;
}
.pagina1-text19 {
  font-weight: 700;
}
.pagina1-text21 {
  display: inline-block;
}
.pagina1-text27 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina1-text28 {
  display: inline-block;
}
.pagina1-text31 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Thin";
}
.pagina1-text33 {
  font-family: "BlauerNue-Bold";
}
.pagina1-text35 {
  display: inline-block;
}
.pagina1-text44 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina1-text45 {
  display: inline-block;
}
.pagina1-text47 {
  font-weight: 700;
}
.pagina1-text49 {
  display: inline-block;
}
.pagina1-text50 {
  display: inline-block;
}
.pagina1-text52 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina1-text53 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 90%;
  height: auto;
  display: inline-block;
}
.pagina1-text55 {
  font-family: ""BlauerNue-Bold"";
}
.pagina1-text56 {
  display: inline-block;
}
.pagina1-text58 {
  font-weight: 700;
}
.pagina1-text64 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina1-text65 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 90%;
  height: auto;
  display: inline-block;
}
.pagina1-text67 {
  font-family: "BlauerNue-Bold";
}
.pagina1-text69 {
  font-family: "BlauerNue-Bold";
}
.pagina1-text71 {
  display: inline-block;
}
.pagina1-text72 {
  width: 510px;
  display: inline-block;
}
.pagina1-text74 {
  font-weight: 700;
}
.pagina1-text79 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina1-text83 {
  font-weight: 700;
}
.pagina1-text86 {
  display: inline-block;
}
.pagina1-text89 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina1-text90 {
  width: auto;
  display: inline-block;
}
.pagina1-text91 {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  width: 100%;
  height: auto;
  display: inline-block;
}
.pagina1-text92 {
  font-family: "BlauerNue-Bold";
  font-weight: 700;
}
.pagina1-text94 {
  width: auto;
  display: inline-block;
}
.pagina1-text96 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pagina1-text98 {
  color: var(--dl-color-principal-azul);
  width: auto;
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
}
.pagina1-sugestao-conteudo {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: var(--dl-color-principal-verde);
}
.pagina1-interno {
  gap: -97px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  overflow: visible;
  position: relative;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.pagina1-texto-ei {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.pagina1-text99 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  height: auto;
  text-align: left;
}
.pagina1-text100 {
  fill: var(--dl-color-secundario-azul-forte);
  color: var(--dl-color-secundario-azul-forte);
  width: 440px;
  align-self: stretch;
  text-align: left;
  letter-spacing: 0em;
}
.pagina1-text102 {
  font-weight: 700;
}
.pagina1-button {
  fill: var(--dl-color-secundario-preto);
  color: var(--dl-color-secundario-preto);
  margin-top: var(--dl-space-space-threeunits);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 52px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-secundario-rosa);
}
.pagina1-container3 {
  flex: 0 0 auto;
  width: 673px;
  height: 100%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
.pagina1-polvo {
  top: -20px;
  left: -54px;
  width: 731px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  transition: 0.3s;
}
.pagina1-polvo:hover {
  transform: rotate(-5deg) scale(1.1);
}
.pagina1-elementos {
  top: 0px;
  left: 35px;
  width: 112px;
  position: absolute;
  object-fit: cover;
  mix-blend-mode: screen;
  background-position: center;
}
.pagina1-fundo {
  top: 0px;
  right: 0px;
  width: 500px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  transition: 0.3s;
}
.pagina1-fundo:hover {
  transform: scale(1.1);
}
@media(max-width: 1200px) {
  .pagina1-interno {
    gap: var(--dl-space-space-twounits);
  }
  .pagina1-texto-ei {
    width: 420px;
    margin-right: 0px;
  }
  .pagina1-container3 {
    width: 540px;
  }
  .pagina1-polvo {
    left: -80px;
    width: 650px;
    bottom: -4px;
    height: 362px;
    position: static;
  }
  .pagina1-fundo {
    top: 0;
    width: 400px;
  }
}
@media(max-width: 991px) {
  .pagina1-text10 {
    width: 100%;
  }
  .pagina1-text72 {
    width: 100%;
  }
  .pagina1-text90 {
    width: auto;
  }
  .pagina1-text94 {
    width: auto;
  }
  .pagina1-text98 {
    color: var(--dl-color-principal-azul);
    width: auto;
    font-weight: 700;
    text-decoration: underline;
  }
}
@media(max-width: 479px) {
  .pagina1-container1 {
    overflow: hidden;
  }
  .pagina1-container2 {
    padding: 0px;
  }
  .pagina1-text10 {
    width: 100%;
  }
  .pagina1-text52 {
    color: var(--dl-color-principal-azul);
    font-weight: 700;
    text-decoration: underline;
  }
  .pagina1-text55 {
    font-family: BlauerNue-Bold;
  }
  .pagina1-text65 {
    width: 100%;
  }
  .pagina1-text72 {
    width: 100%;
  }
  .pagina1-text90 {
    width: 100%;
  }
  .pagina1-text91 {
    width: 100%;
  }
  .pagina1-text94 {
    width: 100%;
  }
  .pagina1-text98 {
    width: 100%;
  }
  .pagina1-sugestao-conteudo {
    height: auto;
    padding: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .pagina1-interno {
    gap: var(--dl-space-space-fourunits);
    height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .pagina1-texto-ei {
    gap: var(--dl-space-space-unit);
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .pagina1-text99 {
    width: 100%;
  }
  .pagina1-text100 {
    width: 100%;
  }
  .pagina1-button {
    margin-top: 0px;
    padding-top: 12px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 12px;
    text-decoration: none;
  }
  .pagina1-container3 {
    width: 100%;
    height: 203px;
  }
  .pagina1-polvo {
    left: 0px;
    right: 0px;
    scale: 1.5;
    width: 100%;
    height: auto;
    margin: auto;
    pointer-events: none;
  }
  .pagina1-elementos {
    top: -10%;
    left: 10%;
    width: 30%;
    position: absolute;
  }
  .pagina1-fundo {
    top: auto;
    width: 100%;
    bottom: 0px;
    transform: rotate(180deg);
  }
}
