.pag3-sanfona4-container1 {
  flex-direction: column;
}
.pag3-sanfona4-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag3-sanfona4-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag3-sanfona4-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag3-sanfona4-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag3-sanfona4-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag3-sanfona4-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag3-sanfona4-container2:active {
  cursor: grabbing;
}
.pag3-sanfona4-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  overflow: hidden;
  position: relative;
  align-self: flex-start;
  align-items: flex-end;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/Pagina 3/imagens/pagina%203%20imagem%205-1500w.png");
  background-position: 100px;
}
.pag3-sanfona4-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag3-sanfona4-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona4-text12 {
  font-family: "BlauerNue-Light";
}
.pag3-sanfona4-text14 {
  font-family: "BlauerNue-Light";
}
.pag3-sanfona4-text17 {
  font-weight: 700;
}
.pag3-sanfona4-image1 {
  width: 200px;
  display: none;
  object-fit: cover;
}
.pag3-sanfona4-container6 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag3-sanfona4-container7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona4-text20 {
  font-weight: 700;
}
.pag3-sanfona4-text23 {
  font-weight: 700;
}
.pag3-sanfona4-container8 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona4-text27 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag3-sanfona4-text31 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag3-sanfona4-image2 {
  flex: 1;
  width: 40%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag3-sanfona4-text33 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Bold";
}
.pag3-sanfona4-text34 {
  font-family: "BlauerNue-Light";
}

@media(max-width: 1200px) {
  .pag3-sanfona4-container3 {
    gap: var(--dl-space-space-threeunits);
    justify-content: flex-start;
    background-position: 0;
  }
}
@media(max-width: 991px) {
  .pag3-sanfona4-container3 {
    padding: 0px;
    align-items: center;
    flex-direction: column;
  }
  .pag3-sanfona4-container4 {
    width: 100%;
    align-items: center;
  }
  .pag3-sanfona4-image1 {
    width: 100%;
    display: flex;
  }
  .pag3-sanfona4-container6 {
    flex-direction: column;
  }
  .pag3-sanfona4-container7 {
    width: 100%;
  }
  .pag3-sanfona4-image2 {
    width: 100%;
    height: auto;
  }
}
@media(max-width: 767px) {
  .pag3-sanfona4-container3 {
    padding: 0px;
    flex-direction: column;
  }
  .pag3-sanfona4-container4 {
    width: 100%;
  }
  .pag3-sanfona4-container6 {
    flex-direction: column;
  }
  .pag3-sanfona4-container7 {
    width: 100%;
  }
  .pag3-sanfona4-image2 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pag3-sanfona4-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag3-sanfona4-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag3-sanfona4-icone-ativo {
    width: 20px;
  }
  .pag3-sanfona4-icone-inativo {
    width: 20px;
  }
  .pag3-sanfona4-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .pag3-sanfona4-container3 {
    gap: var(--dl-space-space-twounits);
    padding: 0px;
    align-items: center;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: flex-start;
  }
  .pag3-sanfona4-container4 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    padding: var(--dl-space-space-oneandhalfunits);
    border-radius: var(--dl-radius-radius-radius8);
  }
  .pag3-sanfona4-image1 {
    width: 100%;
  }
  .pag3-sanfona4-container6 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag3-sanfona4-container7 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag3-sanfona4-image2 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
}
