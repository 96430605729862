.pag2-sanfona3-container1 {
  flex-direction: column;
}
.pag2-sanfona3-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag2-sanfona3-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag2-sanfona3-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag2-sanfona3-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag2-sanfona3-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag2-sanfona3-container2 {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag2-sanfona3-container2:active {
  cursor: grabbing;
}
.pag2-sanfona3-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag2-sanfona3-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag2-sanfona3-image1 {
  flex: 1;
  width: 50%;
  height: 800px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag2-sanfona3-container5 {
  gap: var(--dl-space-space-fiveunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius30);
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-end;
  background-color: var(--dl-color-secundario-azul-fraco);
}
.pag2-sanfona3-container6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 530px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag2-sanfona3-image2 {
  width: 720px;
  object-fit: cover;
  margin-right: -120px;
}
.pag2-sanfona3-container7 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.pag2-sanfona3-image3 {
  flex: 1;
  width: 50%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag2-sanfona3-container8 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag2-sanfona3-link {
  text-decoration: none;
}
.pag2-sanfona3-text16 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Thin";
}
.pag2-sanfona3-text18 {
  font-family: "BlauerNue-Bold";
}
.pag2-sanfona3-text19 {
  width: 100%;
  display: inline-block;
}
.pag2-sanfona3-text21 {
  font-family: "BlauerNue-Bold";
}
.pag2-sanfona3-text22 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
  font-family: "BlauerNue-Thin";
}
.pag2-sanfona3-text24 {
  font-family: "BlauerNue-Bold";
}
.pag2-sanfona3-text25 {
  width: 100%;
  display: inline-block;
}
.pag2-sanfona3-text27 {
  font-weight: 700;
}
.pag2-sanfona3-text29 {
  width: 100%;
  display: inline-block;
}
.pag2-sanfona3-text31 {
  font-weight: 700;
}
.pag2-sanfona3-text33 {
  font-weight: 700;
}
.pag2-sanfona3-text38 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag2-sanfona3-text39 {
  width: 100%;
  display: inline-block;
}
.pag2-sanfona3-text41 {
  font-weight: 700;
}
.pag2-sanfona3-text43 {
  font-weight: 700;
}
.pag2-sanfona3-text47 {
  font-weight: 700;
}
.pag2-sanfona3-text52 {
  font-weight: 700;
}

@media(max-width: 1200px) {
  .pag2-sanfona3-image1 {
    height: auto;
    align-self: stretch;
  }
  .pag2-sanfona3-image2 {
    width: 60%;
  }
}
@media(max-width: 767px) {
  .pag2-sanfona3-container3 {
    flex-direction: column;
  }
  .pag2-sanfona3-container4 {
    width: 100%;
  }
  .pag2-sanfona3-image1 {
    width: 100%;
  }
  .pag2-sanfona3-container5 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .pag2-sanfona3-container6 {
    width: 100%;
  }
  .pag2-sanfona3-image2 {
    width: 100%;
  }
  .pag2-sanfona3-container7 {
    flex-direction: column-reverse;
  }
  .pag2-sanfona3-image3 {
    width: 100%;
  }
  .pag2-sanfona3-container8 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pag2-sanfona3-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag2-sanfona3-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag2-sanfona3-icone-ativo {
    width: 20px;
  }
  .pag2-sanfona3-icone-inativo {
    width: 20px;
  }
  .pag2-sanfona3-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .pag2-sanfona3-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag2-sanfona3-container4 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
  .pag2-sanfona3-image1 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag2-sanfona3-container5 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
    justify-content: flex-start;
  }
  .pag2-sanfona3-container6 {
    width: 100%;
  }
  .pag2-sanfona3-image2 {
    width: 100%;
    margin: 0px;
  }
  .pag2-sanfona3-container7 {
    gap: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .pag2-sanfona3-image3 {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
  .pag2-sanfona3-container8 {
    gap: var(--dl-space-space-unit);
    width: 100%;
  }
}
