.pag3-sanfona3-container1 {
  flex-direction: column;
}
.pag3-sanfona3-topo {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.pag3-sanfona3-text10 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
}
.pag3-sanfona3-botoes {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}
.pag3-sanfona3-icone-ativo {
  width: 34px;
  object-fit: cover;
}
.pag3-sanfona3-icone-inativo {
  width: 34px;
  transform: rotate(180deg);
  object-fit: cover;
}
.pag3-sanfona3-container2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  transition: 0.3s;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.pag3-sanfona3-container2:active {
  cursor: grabbing;
}
.pag3-sanfona3-container3 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pag3-sanfona3-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 60%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pag3-sanfona3-link1 {
  text-decoration: none;
}
.pag3-sanfona3-text12 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}
.pag3-sanfona3-text14 {
  font-weight: 700;
}
.pag3-sanfona3-text16 {
  font-weight: 700;
}
.pag3-sanfona3-text17 {
  font-weight: 700;
}
.pag3-sanfona3-text20 {
  font-weight: 700;
}
.pag3-sanfona3-text22 {
  font-weight: 700;
}
.pag3-sanfona3-image {
  width: 497px;
  align-self: stretch;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius30);
}
.pag3-sanfona3-link2 {
  text-decoration: none;
}
.pag3-sanfona3-text25 {
  font-weight: 700;
}
.pag3-sanfona3-text30 {
  color: var(--dl-color-principal-azul);
  font-weight: 700;
  text-decoration: underline;
}

@media(max-width: 991px) {
  .pag3-sanfona3-container2 {
    flex-direction: column;
  }
  .pag3-sanfona3-container3 {
    flex-direction: column;
  }
  .pag3-sanfona3-container4 {
    width: 100%;
  }
  .pag3-sanfona3-image {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .pag3-sanfona3-container3 {
    margin-right: 0px;
    flex-direction: column;
  }
  .pag3-sanfona3-container4 {
    width: 100%;
  }
  .pag3-sanfona3-image {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pag3-sanfona3-topo {
    padding: var(--dl-space-space-unit);
  }
  .pag3-sanfona3-botoes {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .pag3-sanfona3-icone-ativo {
    width: 20px;
  }
  .pag3-sanfona3-icone-inativo {
    width: 20px;
  }
  .pag3-sanfona3-container2 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .pag3-sanfona3-container3 {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .pag3-sanfona3-container4 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .pag3-sanfona3-image {
    width: 100%;
    height: 200px;
    border-radius: var(--dl-radius-radius-radius16);
  }
}
