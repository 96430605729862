.modelo-slide-classificao-container {
  width: 100%;
  height: 640px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  overflow: visible;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.modelo-slide-classificao-interno {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 3;
  overflow: hidden;
  max-width: 1200px;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  align-items: center;
  border-radius: 30px;
  flex-direction: row;
  background-color: var(--dl-color-principal-branco);
}
.modelo-slide-classificao-image1 {
  flex: 1;
  width: 520px;
  height: 100%;
  object-fit: cover;
}
.modelo-slide-classificao-image2 {
  flex: 1;
  width: 520px;
  height: 100%;
  object-fit: cover;
}
.modelo-slide-classificao-frame1000003600 {
  gap: 32px;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.modelo-slide-classificao-text1 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  width: 100%;
  height: auto;
}
.modelo-slide-classificao-text2 {
  color: rgb(23, 23, 23);
  height: auto;
  align-self: stretch;
  text-align: left;
}
.modelo-slide-classificao-indicaes1 {
  gap: 19px;
  display: none;
  position: relative;
  margin-top: auto;
  align-items: center;
}
.modelo-slide-classificao-djctql11 {
  width: 52px;
  height: 55px;
  border-radius: 6px;
}
.modelo-slide-classificao-vector1 {
  width: 52px;
  height: 55px;
}
.modelo-slide-classificao-vector2 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.modelo-slide-classificao-vector3 {
  width: 52px;
  height: 55px;
}
.modelo-slide-classificao-indicaes2 {
  gap: 19px;
  display: flex;
  position: relative;
  margin-top: auto;
  align-items: center;
}
.modelo-slide-classificao-djctql12 {
  width: 52px;
  height: 55px;
  border-radius: 6px;
}
.modelo-slide-classificao-vector4 {
  width: 52px;
  height: 55px;
}
.modelo-slide-classificao-vector5 {
  width: 52px;
  height: 55px;
  box-sizing: content-box;
}
.modelo-slide-classificao-vector6 {
  width: 52px;
  height: 55px;
}
.modelo-slide-classificao-text3 {
  fill: var(--dl-color-principal-azul);
  color: var(--dl-color-principal-azul);
  display: inline-block;
}
.modelo-slide-classificao-text4 {
  display: inline-block;
}
.modelo-slide-classificao-text6 {
  font-weight: 700;
}
.modelo-slide-classificao-text7 {
  font-weight: 700;
}
.modelo-slide-classificaoroot-class-name {
  height: 100%;
}
.modelo-slide-classificaoroot-class-name1 {
  height: 100%;
}
.modelo-slide-classificaoroot-class-name2 {
  height: 100%;
}
@media(max-width: 1200px) {
  .modelo-slide-classificao-container {
    padding: var(--dl-space-space-twounits);
  }
  .modelo-slide-classificao-interno {
    height: 100%;
  }
  .modelo-slide-classificao-image1 {
    height: 100%;
  }
  .modelo-slide-classificao-image2 {
    height: 100%;
  }
}
@media(max-width: 991px) {
  .modelo-slide-classificao-image1 {
    width: 40%;
  }
}
@media(max-width: 767px) {
  .modelo-slide-classificao-container {
    height: 100%;
  }
  .modelo-slide-classificao-interno {
    height: 100%;
    flex-direction: column;
  }
  .modelo-slide-classificao-image1 {
    width: 20%;
  }
  .modelo-slide-classificao-image2 {
    flex: auto;
    width: 100%;
    height: 400px;
  }
  .modelo-slide-classificao-frame1000003600 {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .modelo-slide-classificao-indicaes2 {
    margin-top: 0px;
  }
}
@media(max-width: 479px) {
  .modelo-slide-classificao-container {
    padding: var(--dl-space-space-unit);
  }
  .modelo-slide-classificao-interno {
    height: 90vh;
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
  }
  .modelo-slide-classificao-image1 {
    flex: 0 0 auto;
    width: 100%;
    height: 240px;
  }
  .modelo-slide-classificao-image2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
  }
  .modelo-slide-classificao-frame1000003600 {
    gap: var(--dl-space-space-unit);
    flex: 1;
    height: auto;
    padding: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .modelo-slide-classificao-indicaes2 {
    gap: var(--dl-space-space-halfunit);
    align-self: stretch;
    justify-content: flex-start;
  }
  .modelo-slide-classificao-djctql12 {
    width: 30px;
    height: 30px;
  }
  .modelo-slide-classificao-vector4 {
    width: 30px;
    height: 30px;
  }
  .modelo-slide-classificao-vector5 {
    width: 30px;
    height: 30px;
  }
  .modelo-slide-classificao-vector6 {
    width: 30px;
    height: 30px;
  }
}
